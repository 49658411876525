import React from 'react';
import { NavLink } from "react-router-dom";
// import vodacash from '../assets/img/images/vodacash.jpeg';

function Footer() {
	return (
		<>
			{' '}
			{/* <!-- footer-area --> */}{' '}
			<footer>
				<div className='footer-area'>
					<div className='footer-top pt-60 pb-40'>
						<div className='container'>
							<div className='row'>
								<div className='col-xl-3 col-lg-4 col-md-6 col-sm-8'>
									<div className='footer-widget mb-30'>
										<div className='footer-content'>
											{/* <span> Got Question ? Call us 24 / 7 </span>{' '} */}
											{/* <a
												href='tel:00233505081548'
												className='contact'>
												+2330505081548{' '}
											</a>{' '} */}
											<p> Follow Us On Social Media </p>{' '}
										</div>{' '}
										<div className='footer-social'>
											<ul>
												<li>
													<NavLink to='https://www.facebook.com/profile.php?id=61565514875735&mibextid=LQQJ4d' target="blank">
														<i className='fa-brands fa-facebook-f'> </i>{' '}
													</NavLink>{' '}
												</li>{' '}
												<li>
													<NavLink to='https://x.com/africamarkets0?s=21' target="blank">
														<i className='fa-brands fa-twitter'> </i>{' '}
													</NavLink>{' '}
												</li>{' '}
												<li>
													<NavLink to='https://www.tiktok.com/@africa.markets?_t=8peuC4ZS74Y&_r=1' target="blank">
														<i className='fa-brands fa-tiktok'> </i>{' '}
													</NavLink>{' '}
												</li>{' '}
												<li>
													<NavLink to='https://www.instagram.com/africamarkets?igsh=MWF5ZjM3bzZyeHZiNA%3D%3D&utm_source=qr' target="blank">
														<i className='fa-brands fa-instagram'> </i>{' '}
													</NavLink>{' '}
												</li>{' '}
											</ul>{' '}
										</div>{' '}
									</div>{' '}
								</div>{' '}
								<div className='col-xl-3 col-lg-3 col-md-6 col-sm-4'>
									<div className='footer-widget mb-30'>
										<div className='fw-title mb-20'>
											<h2 className='title'> INFORMATION </h2>{' '}
										</div>{' '}
										<div className='fw-link'>
											<ul>
												<li>
													<NavLink to='/'> Home </NavLink>{' '}
												</li>{' '}
												<li>
													<NavLink to='/about'> About Us </NavLink>{' '}
												</li>{' '}
												<li>
													<NavLink to='/contact'> Contact Us </NavLink>{' '}
												</li>{' '}
												<li>
													<NavLink to='/faq'> FAQ </NavLink>{' '}
												</li>{' '}
												<li>
													<NavLink to='/orderhow'> How to Order </NavLink>{' '}
												</li>{' '}
											</ul>{' '}
										</div>{' '}
									</div>{' '}
								</div>{' '}
								<div className='col-xl-3 col-lg-2 col-md-6 col-sm-6'>
									<div className='footer-widget mb-30'>
										<div className='fw-title mb-20'>
											<h2 className='title'> MY ACCOUNT </h2>{' '}
										</div>{' '}
										<div className='fw-link'>
											<ul>
												<li>
													<NavLink to='/orders'>
														{' '}
														Track My Order{' '}
													</NavLink>{' '}
												</li>{' '}
												<li>
													<NavLink to='/cart'> View Cart </NavLink>{' '}
												</li>{' '}
												<li>
													<NavLink to='/support'> Help </NavLink>{' '}
												</li>{' '}
												<li>
													<NavLink to='/wishlist'> My Wishlist </NavLink>{' '}
												</li>{' '}
												
											</ul>{' '}
										</div>{' '}
									</div>{' '}
								</div>{' '}
								<div className='col-xl-3 col-lg-3 col-md-6 col-sm-6'>
									<div className='footer-widget mb-30'>
										<div className='fw-title mb-20'>
											<h2 className='title'> POLICIES </h2>{' '}
										</div>{' '}
										<div className='fw-link'>
											<ul>
												<li>
													<NavLink to='/paymentm'> Payment Methods </NavLink>{' '}
												</li>{' '}
												<li>
													<NavLink to='/return'> Products Returns </NavLink>{' '}
												</li>{' '}
												<li>
													<NavLink to='/privacy'> Privacy Policy </NavLink>{' '}
												</li>{' '}
												{/* Uncomment and modify as needed */}{' '}
												{/* <li>
                                    <NavLink to='/shipping'> Shipping </NavLink>
                                </li> */}{' '}
												<li>
													<NavLink to='/termcon'>
														{' '}
														Terms and Conditions{' '}
													</NavLink>{' '}
												</li>{' '}
											</ul>{' '}
										</div>{' '}
									</div>{' '}
								</div>{' '}
							</div>{' '}
						</div>{' '}
					</div>{' '}
					<div className='footer-bottom'>
						<div className='container'>
							<div className='row'>
								<div className='col-lg-6 col-md-7'>
									<div className='copyright-text'>
										<p> Copyright© 2024 Africa Market - All Rights Reserved </p>{' '}
									</div>{' '}
								</div>{' '}
								{/* <div className='col-lg-6 col-md-5'>
									<div className='cart-img text-end'>
										<img
											className='footer-img'
											src={vodacash}
											alt='telecel' */}
										{/* />{' '} */}
										{/* {
                                                												<img
                                                													className='footer-img'
                                                													src={vodacash}
                                                													alt='cards Image'
                                                												/>
                                                											}{' '} */}{' '}
									{/* </div>{' '} */}
								{/* </div>{' '} */}
							</div>{' '}
						</div>{' '}
					</div>{' '}
				</div>{' '}
			</footer>{' '}
			{/* footer-area-end  */}{' '}
		</>
	);
}

export default Footer;