import React, { useEffect, useState } from "react";
import axios from "axios";
import { API_BASE_URL } from "../config/connect";
import Header from "../components/Header";
import { ToastContainer, toast } from "react-toastify";
import shadows from "@mui/material/styles/shadows";
import { Link, useParams, NavLink } from "react-router-dom";

const styles = `
.card-description {
    margin:0px !important;
    padding-top:0px !important;
    font-size:13px;
} 

.client {
    text-align: left;
    background-color: #66AF4E;
    padding: 10px;
    border-bottom-right-radius: 25px;
    /* width: fit-content; */

    color: white;
    max-width: 100%;
}

.client-row {
    padding: 5px;
    display: flex;
    justify-content: flex-end;
    /* Align to the right */
    max-width: 70%;
}

.message-container-client {
    padding: 5px;
    flex: 0 0 10em;
    display: flex;
    justify-content: flex-end;
    /* Align to the right */
    max-width: 70%;
    margin-left: auto;
    /* Aligns to the right */
    margin-right: 0;
    min-width: 50px !important;
}

.message-container-admin {
    padding: 5px;
    flex: 0 0 10em;
    display: flex;
    justify-content: flex-start;
    /* Align to the right */
    max-width: 70%;
    min-width: 150px !important;
    /* Aligns to the right */
    margin-left: 0;
    /* Resets margin */
}

.admin-row {
    padding: 5px;
    display: flex;
    justify-content: flex-start;
    /* Align to the right */
    max-width: 70%;
    /* Align to the right */
}

.admin {   
    text-align: left;
    background-color: #FFA500;
    padding: 10px;
    border-bottom-right-radius: 25px;
    /* width: fit-content; */
    color: white;
    max-width: 100%;
}

.messagebox {
    height: 40vh;
    overflow: auto;
    min-width: 150px !important;
    background-color:#554433;
    margin-bottom:20px;
    border-radius:10px;
}

.initial-details > p {
    margin-bottom: 0px;
}

.client-message-date {
    text-align: right;
}

.admin-message-date {
    text-align: left;
}

textarea{
    resize: none;
    width: 100%;
    min-width: 150px !important;
}

button:hover{
    background-color:#f6921e !important;
}
`;

const MessageDetails = () => {
  const [businessname, setBusinessName] = useState("");
  const [ordercode, setOrderCode] = useState("");
  const [orderstatus, setOrderStatus] = useState("");
  const [servicename, setServiceName] = useState("");
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState({
    messagebody: "",
  });
  const [trackno, setTrackno] = useState("");
  let { mescode } = useParams();
  const [loading, setLoading] = useState(false);

  const changeStatus = () => {
    // console.log(mescode);
    axios
      .get(
        `https://ghmarkets.net/test/ghmarketconnect/?ghw=messages&svg=changestatus&msgcode=${mescode}`
      )
      .then((response) => {
        if (response.data) {
          // console.log("Message status changed "+response.data);
        } else {
          // console.log("Change did not work");
        }
      });
  };

  const getHeaderDetails = () => {
    changeStatus();
    axios
      .get(
        `https://ghmarkets.net/test/ghmarketconnect/?ghw=messages&svg=getheaddetails&msgcode=${mescode}`
      )
      .then((response) => {
        if (response.data) {
          setBusinessName(response.data.businessname);
          setOrderCode(response.data.ordercode);
          setServiceName(response.data.servicename);
          setMessages(response.data.messages);
          setTrackno(response.data.trackingno);
          switch (response.data.orderstatus) {
            case "1":
              setOrderStatus("New Service/Pending Quote");
              break;
            case "2":
              setOrderStatus("Pending Delivery");
              break;
            case "3":
              setOrderStatus("Delivered");
              break;
            default:
              break;
          }
        } else {
          // console.log("no message got ");
        }
      });
  };

  const getEasyTime = (timestamp) => {
    var date = new Date(timestamp);
    var currentDate = new Date();
    var diff = currentDate - date;
    var oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    var oneMonth = 30 * oneDay; // approximate number of milliseconds in a month

    var days = Math.round(Math.abs(diff) / oneDay);
    var months = Math.round(Math.abs(diff) / oneMonth);

    if (days === 0) {
      return "today";
    } else if (days === 1) {
      return "yesterday";
    } else if (days <= 7) {
      return days + " days ago";
    } else if (months === 1) {
      return "1 month ago";
    } else if (months < 1) {
      return days + " days ago";
    } else if (months < 6) {
      return months + " months ago";
    } else {
      // Return the actual date if it's more than 6 months ago
      return date.toLocaleDateString("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric",
      });
    }
  };

  const handleChange = (event) => {
    const textMessage = event.target.value;
    setNewMessage({ messagebody: textMessage });
    // console.log(textMessage);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (newMessage.messagebody.trim() !== "") {
      setLoading(true);
      axios
        .post(
          `https://ghmarkets.net/test/ghmarketconnect/?ghw=messages&svg=savemessage&trckno=${trackno}`,
          newMessage
        )
        .then((res) => {
          // console.log(res.data);
          // Reset the input field after successful submission
          setNewMessage({ messagebody: "" });
          // Fetch updated messages
          getHeaderDetails();
        })
        .catch((error) => {
          console.error("Error:", error);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      alert("Please enter a message.");
    }
    document.getElementById("ticketmessage").value = "";
  };

  useEffect(() => {
    getHeaderDetails();
  }, []);

  return (
    <>
      <Header />
      <section>
        <div className="container pb-30 pt-30">
          <style>{styles}</style>
          <div className="row container d-flex justify-content-center">
            <div className="col-lg-12 grid-margin stretch-card">
              <NavLink to="/messages">
                <span>
                  <i className="fa fa-arrow-left"></i> Go back to messages
                </span>
              </NavLink>
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title">{servicename}</h4>
                  <p className="card-description">
                    <strong>Business Name: {businessname}</strong>
                  </p>
                  <p className="card-description">
                    <strong>Order code: {ordercode}</strong>
                  </p>
                  <p className="card-description">
                    <strong>Order Status: {orderstatus}</strong>
                  </p>
                  <div class="card-body">
                    <div className="messagebox">
                      {messages.map((message) => {
                        let _class = "";
                        let _client = "";
                        let _message = "";
                        let _messagebox = "";
                        if (message.sendby === "2") {
                          _class = "admin-row";
                          _client = "admin";
                          _message = "admin-message-date";
                          _messagebox = "message-container-admin";
                        } else {
                          _class = "client-row";
                          _client = "client";
                          _message = "client-message-date";
                          _messagebox = "message-container-client";
                        }
                        return (
                          <div
                            key={message.id}
                            className={`col-md-12 d-flex no-block align-items-center ${_messagebox}`}
                          >
                            <div className={_class}>
                              <div className={_client}>
                                <p
                                  style={{
                                    padding: 0,
                                    margin: 0,
                                    color: "white",
                                  }}
                                >
                                  {message.message}
                                </p>
                                <p
                                  style={{
                                    color: "#CCC6C5",
                                    margin: 0,
                                    padding: "2px",
                                  }}
                                  className={`${_message} -pt-5`}
                                >
                                  <span style={{ font: "10px" }}>
                                    {getEasyTime(message.datesent)}
                                  </span>
                                </p>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    <form onSubmit={handleSubmit} id="service-form">
                      <div className="row" style={{ marginTop: "20px" }}>
                        <div className="col-md-12 fieldspaceout input-group mb-3">
                          <label for=""></label>
                          <textarea
                            className="form-control"
                            cols={30}
                            rows={2}
                            name="ticketmessage"
                            id="ticketmessage"
                            placeholder="Type a message..."
                            onChange={handleChange}
                          ></textarea>
                          <div className="input-group-append">
                            <button
                              disabled={loading}
                              type="submit"
                              className="btn btn-outline-secondary"
                              style={{
                                backgroundColor: "#554433",
                              }}
                            >
                              <i className="fa fa-paper-plane"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MessageDetails;
