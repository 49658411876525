import React from "react";
import Binacular from "../assets/img/images/binoculars.389fc56a.svg";
import SearcIcon from "../assets/img/images/searcicon.png"

const Nodata = ({ searchTerm }) => {
  return (
    <>
      <div className="text-center">
        <img src={Binacular} alt="search img" />
        <h3>No results found.</h3>
        {/* <p>
          {" "}
          There are no results for <b>"{searchTerm}"</b>.
        </p> */}
        {/* <p>
          - Check your spelling for typing errors <br></br>- Try searching with short and
          simple keywords
        </p> */}
      </div>
    </>
  );
};

export default Nodata;
