import React, { useState, useEffect, useCallback } from "react";
import { NavLink } from "react-router-dom";
import Leftmenu from "../components/Leftmenu";
import Navbar from "../components/Navbar";
import { API_BASE_URL } from "../config/connect";
import sidebar_product01 from "../assets/img/product/sidebar_product01.jpg";
import sidebar_product02 from "../assets/img/product/sidebar_product02.jpg";
import sidebar_product03 from "../assets/img/product/sidebar_product03.jpg";
import Topbar from "../components/Topbar";
import Livemarket from "../components/Livemarket";
import Profservicessingle from "../components/Profservicessingle";
import Header from "../components/Header";
import Trendingproducts from "../components/Trendingproducts";
import Pagination from "../components/Pagination";
import Filtering from "../components/Filtering";
import useFetch from "../hooks/useFetch";
function Profservices() {
  // const [products, setProducts] = useState([]);
  // const [loading, setLoading] = useState(true);
  const [isGrid, setIsGrid] = useState(true);

  const { data, loading, error } = useFetch(
    `https://ghmarkets.net/test/ghmarketconnect/?ghw=profservices&svg=list&cat=CAT654b8e18accf4`,
    {},
    [],
    null
  );
  const [filteredProducts, setFilteredProducts] = useState(data);
  // const getAllProducts = () => {
  //   fetch(`https://ghmarkets.net/test/ghmarketconnect/?ghw=profservices&svg=list&cat=CAT654b8e18accf4`)
  //     .then((response) => response.json())
  //     .then((json) => {
  //       //console.log(json['results'])
  //       setProducts(json["results"]);
  //       setLoading(false);
  //     });
  // };

  // useEffect(() => {
  //   getAllProducts();
  // }, []);

  // Function to handle filtering
  const applyFilter = useCallback(
    (searchText, minPrice, maxPrice) => {
      const filtered = data?.filter((product) => {
        const productName = product.name?.toLowerCase() || "";
        const productDescription = product.description?.toLowerCase() || "";
        const productModel = product.model?.toLowerCase() || "";
        const productRegionName = product.coveragezone?.toLowerCase() || "";
        const productSubCategoryName =
          product.subcategoryname?.toLowerCase() || "";
        const productBrandName = product.brandname?.toLowerCase() || "";

        const searchTextMatch =
          productName.includes(searchText.toLowerCase()) ||
          productDescription.includes(searchText.toLowerCase()) ||
          productModel.includes(searchText.toLowerCase()) ||
          productRegionName.includes(searchText.toLowerCase()) ||
          productSubCategoryName.includes(searchText.toLowerCase()) ||
          productBrandName.includes(searchText.toLowerCase());

        let prodPrice = product.price == null ? 0 : product.price;
        minPrice = isNaN(minPrice) ? 0 : minPrice;

        const priceMatch =
          (!minPrice || parseFloat(prodPrice) >= parseFloat(minPrice)) &&
          (!maxPrice || parseFloat(prodPrice) <= parseFloat(maxPrice));

        return searchTextMatch && priceMatch;
      });

      setFilteredProducts(filtered);
      setCurrentPage(1); // Reset to first page after filtering
    },
    [data]
  );

  /**
   * Pagination data starts here
   */
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(9);

  // Get current posts
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = filteredProducts?.slice(
    indexOfFirstPost,
    indexOfLastPost
  );

  const paginate = (data) => {
    let currentNumber = data.selected + 1;
    setCurrentPage(currentNumber);
  };
  //Pagination data ends here

  const toggleView = (viewType) => {
    setIsGrid(viewType);
  };

  return (
    <div>
      <Header />

      <main>
        <div className="shop-area pt-90 pb-90">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-3 col-lg-4 col-md-8 col-sm-10 order-2 order-lg-0">
                <aside className="shop-sidebar">
                  <Filtering
                    onFilter={applyFilter} // Pass the applyFilter function
                    data={data} // Pass the products data to the Filtering component
                  />
                  <Trendingproducts>Service Providers</Trendingproducts>
                </aside>
              </div>
              <div className="col-xl-9 col-lg-8">
                <div className="shop-top-wrap mb-35">
                  <div className="shop-top-left">
                    <h5 className="title">Professional Services</h5>
                  </div>
                  <div className="shop-top-right">
                    <form action="#">
                      <label htmlFor="shortBy">sort By</label>
                      {/* <select id="shortBy" name="select" className="form-select" aria-label="Default select example">
                                            <option value="">Sorting</option>
                                            <option>Free Shipping</option>
                                            <option>Best Match</option>
                                            <option>Newest Item</option>
                                            <option>Size A - Z</option>
                                        </select> */}
                    </form>
                    <ul>
                      <li>View</li>
                      <li
                        className={isGrid && "active"}
                        onClick={() => {
                          toggleView(true);
                        }}
                      >
                        <NavLink to="">
                          <i className="fa-solid fa-table-cells"></i>
                        </NavLink>
                      </li>
                      <li
                        className={isGrid || "active"}
                        onClick={() => {
                          toggleView(false);
                        }}
                      >
                        <NavLink to="">
                          <i className="fa-solid fa-bars"></i>
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="row">
                  <Profservicessingle
                    slicedPosts={currentPosts}
                    loading={loading}
                    view={isGrid}
                  />
                </div>
                <div className="shop-bottom-wrap">
                  {filteredProducts?.length > 0 ? (
                    <div className="shop-bottom-top">
                      {/* <h5 className="title">Shop</h5> */}
                      <p>
                        Showing {indexOfFirstPost + 1} to{" "}
                        {indexOfLastPost > filteredProducts.length
                          ? filteredProducts.length
                          : indexOfLastPost}{" "}
                        of {filteredProducts.length} Item(s)
                      </p>
                      <Pagination
                        postsPerPage={postsPerPage}
                        totalPosts={filteredProducts.length}
                        paginate={paginate}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="shop-bottom-box">
                    <div className="shop-bottom-left">
                      <form action="#">
                        {/* <select id="short-By" name="select" className="form-select" aria-label="Default select example">
                                                <option value="">Show 09</option>
                                                <option>Show 12</option>
                                                <option>Show 08</option>
                                                <option>Show 06</option>
                                                <option>Show 03</option>
                                            </select> */}
                      </form>
                    </div>
                    <div className="shop-bottom-right">
                      <form action="#">
                        {/* <select id="short-by" name="select" className="form-select" aria-label="Default select example">
                                                <option value="">Default sorting</option>
                                                <option>Free Shipping</option>
                                                <option>Best Match</option>
                                                <option>Newest Item</option>
                                                <option>Size A - Z</option>
                                            </select> */}
                      </form>
                      <ul>
                        <li
                          className={isGrid && "active"}
                          onClick={() => {
                            toggleView(true);
                          }}
                        >
                          <NavLink to="#">
                            <i className="fa-solid fa-table-cells"></i>
                          </NavLink>
                        </li>
                        <li
                          className={isGrid || "active"}
                          onClick={() => {
                            toggleView(false);
                          }}
                        >
                          <NavLink to="#">
                            <i className="fa-solid fa-bars"></i>
                          </NavLink>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default Profservices;
