import React from "react";
import Leftmenuproducts from "./Leftmenuproducts";
import { NavLink } from "react-router-dom";
import { useProductData } from "./ProductsContextApi";

function Leftmenusubcat(props) {
  const allsubcategories = useProductData().subcategories;
  const specSubcat = allsubcategories?.filter(
    (subcategory) => subcategory.categorycode === props.catcode
  );

  /*
   * The following function will fetch all sub-categories
   * based on the category
   */
  // const getAllSubCategories = () => {
  //   fetch(`https://ghmarkets.net/test/ghmarketconnect/?ghw=subcategories&svg=list&cat=${props.catcode}`)
  //     .then((response) => response.json())
  //     .then((json) => {
  //       // console.log(json['results'])
  //       setsubCategories(json["results"]);
  //     });
  // };

  // useEffect(() => {
  //   getAllSubCategories();
  // }, []);

  return specSubcat
    ? specSubcat.map((post, index) => {
        return (
          <li key={index} className="sub-column-item">
            <NavLink to="">{post.name}</NavLink>
            <ul>
              <Leftmenuproducts subcatcode={post.code} />
            </ul>
          </li>
        );
      })
    : "";
}

export default Leftmenusubcat;
