import React from "react";
// import Binacular from "../assets/img/images/binoculars.389fc56a.svg";
// import SearcIcon from "../assets/img/images/searcicon.png"
import "../assets/css/Blackfriday.css";

const BlackFriday = ({ searchTerm }) => {
  return (
    <>
      {/* <div className="row justify-content-center"> */}
                  {/* Marquee starts here */}
                  <div className="marquee-container">
                    <div className="marquee">
                      <span>Coming Soon</span>
                      <span>Coming Soon</span>
                      <span>Coming Soon</span>
                    </div>
                  </div>
                  {/* Marquee ends here */}
                {/* </div> */}
    </>
  );
};

export default BlackFriday;
