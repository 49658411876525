import React from 'react';
import { ToastContainer } from 'react-toastify';
import Header from '../components/Header';

const Contact = () => {
	return (
		<>
			<Header />
			<section className='contact-section py-5'>
				<div className='container-fluid'>
					<div className='row justify-content-center'>
						<div className='col-lg-8'></div>
						<div className='card shadow'>
							<div className="card-body">
								<h2 className='card-title text-center mb-4'>Contact Us{' '}</h2>{' '}
								<p className='card-text lead text-center mb-4'>
									<strong>Contact:</strong> (+233) 0302 915 873{' '}
								</p>
								<p className='card-text lead text-center mb-4'>
									<strong>Email:</strong> support@africamarkets.net{' '}
								</p>
								<p className='card-text lead text-center mb-4'>
									<strong>Location:</strong> V357 Mabel ST GS-0330-8861, The Oyster Shell, 41 A McCarthy Hill, Accra, Ghana.{' '}
								</p>

							</div>{' '}
						</div>{' '}
					</div>{' '}
				</div>{' '}
			</section>{' '}

			<ToastContainer />
		</>
	);
};

export default Contact;
