import React, { useState, useEffect } from "react";
import "../assets/css/PriceRangeSlider.css";
import { API_BASE_URL } from "../config/connect";
const Swal = require("sweetalert2");

const Automobile = ({ onFilterChange, data }) => {
  const [filters, setFilters] = useState({
    make: "",
    model: "",
    price: "",
    year: "",
  });
  const [automobileMake, setMake] = useState([]);
  const [automobileModel, setModel] = useState([]);
  const [automobileModelYear, setModelYear] = useState([]);
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(50000); // Default max value
  const [sliderMin, setSliderMin] = useState(0);
  const [sliderMax, setSliderMax] = useState(50000); // Default slider max value

  // Fetch makes and years on component mount
  useEffect(() => {
    getAllMake();
    getAllModelYear();
  }, []);

  // Calculate the minimum and maximum price from the data
  useEffect(() => {
    if (data && data.length > 0) {
      const prices = data.map((item) => parseFloat(item.price)); // Ensure price is a float
      const minDataPrice = Math.min(...prices);
      const maxDataPrice = Math.max(...prices);

      // Only update if the data-based values are different from the current ones
      if (minDataPrice !== sliderMin || maxDataPrice !== sliderMax) {
        setSliderMin(minDataPrice);
        setSliderMax(maxDataPrice);
        setMinPrice(minDataPrice); // Set minPrice to match the data
        setMaxPrice(maxDataPrice); // Set maxPrice to match the data
      }
    }
  }, [data, sliderMin, sliderMax]);

  const getAllMake = () => {
    fetch(
      `https://ghmarkets.net/test/ghmarketconnect/?ghw=productservices&svg=automobilemake`
    )
      .then((response) => response.json())
      .then((json) => {
        setMake(json.results);
      })
      .catch((error) => console.error("Error fetching makes:", error));
  };

  const getAllModel = (makeId) => {
    fetch(
      `https://ghmarkets.net/test/ghmarketconnect/?ghw=productservices&svg=automobilemodel&makeId=${makeId}`
    )
      .then((response) => response.json())
      .then((json) => {
        setModel(json.results);
      })
      .catch((error) => console.error("Error fetching models:", error));
  };

  const getAllModelYear = () => {
    fetch(
      `https://ghmarkets.net/test/ghmarketconnect/?ghw=productservices&svg=automobilemodelyear`
    )
      .then((response) => response.json())
      .then((json) => {
        setModelYear(json.results);
      })
      .catch((error) => console.error("Error fetching model years:", error));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
      ...(name === "make" && { model: "" }), // Reset model when make changes
    }));

    // Fetch models based on selected make
    if (name === "make") {
      getAllModel(value);
    }
  };

  // Handle price validation and filtering
  const handleRangeInput = (e) => {
    const { className, value } = e.target;
    const newValue = parseFloat(value); // Use float for precise comparison

    if (className.includes("min-range")) {
      if (newValue >= sliderMin && newValue <= maxPrice) {
        setMinPrice(newValue);
      } else {
        Swal.fire({
          icon: "error",
          title: "Invalid Input",
          text: `Minimum price must be between ${sliderMin} and ${maxPrice}`,
          confirmButtonColor: "#F6921E",
          confirmButtonText: "OK",
        });
      }
    } else {
      if (newValue <= sliderMax && newValue >= minPrice) {
        setMaxPrice(newValue);
      } else {
        Swal.fire({
          icon: "error",
          title: "Invalid Input",
          text: `Maximum price must be between ${minPrice} and ${sliderMax}`,
          confirmButtonColor: "#F6921E",
          confirmButtonText: "OK",
        });
      }
    }
  };

  // Apply filter whenever the search text, minPrice, or maxPrice changes
  useEffect(() => {
    onFilterChange(
      filters.make,
      filters.model,
      filters.year,
      minPrice,
      maxPrice
    );
  }, [filters.make, filters.model, filters.year, minPrice, maxPrice, data]);

  return (
    <div className="automobile-filters mb-5">
      <div className="form-row">
        <div className="form-group">
          <div className="row">
            <div className="col-md-12 mb-3">
              <label htmlFor="make">Make</label>
              <select
                name="make"
                id="make"
                className="form-control"
                value={filters.make}
                onChange={handleInputChange}
              >
                <option value="">Select Make</option>
                {automobileMake.map((make) => (
                  <option key={make.id} value={make.code}>
                    {make.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-md-12 mb-3">
              <label htmlFor="model">Model</label>
              <select
                name="model"
                value={filters.model}
                onChange={handleInputChange}
                disabled={!filters.make}
                className="form-control"
              >
                <option value="">Select Model</option>
                {filters.make &&
                  automobileModel.map((model) => (
                    <option key={model.id} value={model.code}>
                      {model.name}
                    </option>
                  ))}
              </select>
            </div>
            <div className="col-md-12 mb-3">
              <label htmlFor="year">Year</label>
              <select
                name="year"
                value={filters.year}
                onChange={handleInputChange}
                className="form-control"
              >
                <option value="">Select Year</option>
                {automobileModelYear.map((year) => (
                  <option key={year.id} value={year.modelyear}>
                    {year.modelyear}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-md-12 mb-3">
              <label htmlFor="price">Price Range</label>
              <div className="price-input-container">
                <div className="slider-container">
                  <div
                    className="price-slider"
                    style={{
                      left: `${((minPrice - sliderMin) / (sliderMax - sliderMin)) * 100}%`,
                      right: `${100 - ((maxPrice - sliderMin) / (sliderMax - sliderMin)) * 100}%`,
                    }}
                  />
                </div>
                <div className="range-input mb-4">
                  <input
                    type="range"
                    className="min-range"
                    min={sliderMin} // Dynamic min based on data
                    max={sliderMax} // Dynamic max based on data
                    step="0.01" // For more precise control, use small steps
                    value={minPrice}
                    onChange={handleRangeInput}
                    aria-label="Minimum price range"
                  />
                  <input
                    type="range"
                    className="max-range"
                    min={sliderMin} // Dynamic min based on data
                    max={sliderMax} // Dynamic max based on data
                    step="0.01" // For more precise control, use small steps
                    value={maxPrice}
                    onChange={handleRangeInput}
                    aria-label="Maximum price range"
                  />
                </div>
                <div className="price-input">
                  <div className="price-field">
                    <span>Minimum Price</span>
                    <input
                      type="number"
                      className="min-input"
                      value={minPrice}
                      onChange={(e) => {
                        const newValue = parseFloat(e.target.value);
                        if (newValue >= sliderMin && newValue <= maxPrice) {
                          setMinPrice(newValue);
                        } else {
                          Swal.fire({
                            icon: "error",
                            title: "Invalid Input",
                            text: `Minimum price must be between ${sliderMin} and ${maxPrice}`,
                            confirmButtonColor: "#F6921E",
                            confirmButtonText: "OK",
                          });
                        }
                      }}
                      aria-label="Minimum price input"
                    />
                  </div>
                  <div className="price-field">
                    <span>Maximum Price</span>
                    <input
                      type="number"
                      className="max-input"
                      value={maxPrice}
                      onChange={(e) => {
                        const newValue = parseFloat(e.target.value);
                        if (newValue <= sliderMax && newValue >= minPrice) {
                          setMaxPrice(newValue);
                        } else {
                          Swal.fire({
                            icon: "error",
                            title: "Invalid Input",
                            text: `Maximum price must be between ${minPrice} and ${sliderMax}`,
                            confirmButtonColor: "#F6921E",
                            confirmButtonText: "OK",
                          });
                        }
                      }}
                      aria-label="Maximum price input"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Automobile;
