import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { API_BASE_URL } from "../config/connect";
import axios from "axios";

const ServicePaySummary = ({
  handlePayment,
  payLoading,
  userInfo,
  payInfo,
  toQuote,
}) => {
  const [taxPercentage, setTaxPercentage] = useState(0);
  const [taxAmt, setTaxAmt] = useState(0);
  const [enableBtn, setEnableBtn] = useState(false);
  const cartProducts = useSelector((state) => state.cart);
  const track = JSON.parse(localStorage.getItem(btoa("tckcd")));
  const [toSummary, setToSummary] = useState(null);

  useEffect(() => {
    const fetchQuote = async () => {
      try {
        const quote_response = await axios.post(
          `https://ghmarkets.net/test/ghmarketconnect/?ghw=requestservice&svg=detquote`,
          { track_code: track },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const quote = quote_response.data.quote;
        setToSummary(quote);
      } catch (error) {
        console.error("Error fetching quote:", error);
      }
    };

    fetchQuote();
  }, [track]); // Fetch quote only when track changes

  useEffect(() => {
    const fetchTaxes = async () => {
      try {
        const res = await fetch(
          `https://ghmarkets.net/test/ghmarketconnect/?ghw=processpayment&svg=gettaxes`
        );
        const response = await res.json();
        const taxes = response.data;
        setTaxPercentage(taxes);
        setTaxAmt(((taxes * toSummary) / 100).toFixed(2));
      } catch (error) {
        console.error("Error fetching taxes:", error);
      }
    };

    if (toSummary !== null) {
      fetchTaxes();
    }
  }, [toSummary]); // Fetch taxes when toSummary changes

  useEffect(() => {
    if (
      userInfo &&
      Object.keys(userInfo).length > 0 &&
      payInfo &&
      Object.keys(payInfo).length > 0 &&
      toSummary !== null &&
      !isNaN(toSummary)
    ) {
      setEnableBtn(false);
    } else {
      setEnableBtn(true);
    }
  }, [userInfo, payInfo, toSummary]);

  return (
    <>
      <div className="col-sm-3">
        <div className="card">
          <div className="card-header">Summary</div>
          <div className="card-body">
            <div className="coupon-product-content">
              <div className="cart-summary-item">
                <div className="cart-summary-item-label">Subtotal</div>
                <div className="cart-summary-item-content">
                  GH₵ {parseFloat(toSummary || 0).toFixed(2)}
                </div>
              </div>
              <div className="cart-summary-item mt-2">
                <div className="cart-summary-item-label">Tax</div>
                <div className="cart-summary-item-content">GH₵ {taxAmt}</div>
              </div>

              <hr className="mt-15 mb-15" />

              <div className="cart-summary-item">
                <div className="cart-summary-item-label">Total</div>
                <div className="cart-summary-item-content">
                  GH₵{" "}
                  {(parseFloat(toSummary || 0) + parseFloat(taxAmt)).toFixed(2)}
                </div>
              </div>

              {handlePayment && (
                <>
                  <button
                    className="btn btn-block mt-5"
                    onClick={handlePayment}
                    disabled={enableBtn}
                  >
                    {!payLoading ? (
                      <>
                        Pay now(GH₵
                        {(
                          parseFloat(toSummary || 0) + parseFloat(taxAmt)
                        ).toFixed(2)}
                        )
                      </>
                    ) : (
                      <>
                        Redirecting... (GH₵
                        {(
                          parseFloat(toSummary || 0) + parseFloat(taxAmt)
                        ).toFixed(2)}
                        )
                      </>
                    )}
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServicePaySummary;
