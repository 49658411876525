import { configureStore } from "@reduxjs/toolkit";
import cartSlice from "./cartSlice";
import wishlist from "./wishListSlice";
import compare from "./compareSlice";

const store = configureStore({
  reducer: {
    cart: cartSlice,
    wishlist: wishlist,
    compareproduct: compare,
  },
});

export default store;
