import React from "react";
import { NavLink } from "react-router-dom";
import { add } from "../store/cartSlice";
import { useDispatch } from "react-redux";
import ViemoreProdSkeleton from "./ViemoreProdSkeleton";
import Nodata from "./Nodata";

function Productbrandlist({ slicedPosts, loading, view }) {
  const dispatch = useDispatch();

  const addToCart = (product) => {
    dispatch(add(product));
  };

  if (loading) {
    return <ViemoreProdSkeleton />;
  } else if (slicedPosts?.length === 0) {
    return <Nodata />;
  } else {
    return (
      <>
        {slicedPosts?.map((post, index) => (
          <div key={index} className={view ? "col-xl-4 col-lg-6 col-md-6 col-sm-6" : "col-12 mb-3"}>
            <div className={`${view || 'row custom'} shop-product-item mb-30`} style={{ position: "relative" }}>
              <div className={`${view || 'col-3 col-xs-5'} shop-thumb`} >
                <NavLink to={`/shopdetail/${post.code}`}>
                  <img
                    src={post.previewpicture || post.picture}
                    alt="img"
                    width="200px"
                    height="auto"
                  />

{post.freedelivery === '1' && (
      <div class="mo"
        style={{
          position: "absolute",
          top: "1px",   // Adjust the position as needed
          right: "3px", // Change this to `left: "10px"` for the left corner
          backgroundColor: "#F6921E", // Orange background
          color: "white", // White text color
          padding: "1px 10px", // Padding for better appearance
          fontSize: "12px", // Adjust text size as needed
          borderRadius: "5px", // Rounded corners
          height:"20px",
        }}
      >
        Free Delivery
      </div>
    )}
                </NavLink>
                {/* <span>New</span> */}
              </div>
              {/* {`/products/${post.code}`} */}
              <div className={`${view || 'col-6 col-xs-5' } shop-content`}>
                <h2 className="title">
                  <NavLink to={`/shopdetail/${post.code}`}>{post.name}</NavLink>
                </h2>
                <ul className="tag">
                  <li>
                    Sold by{" "}
                    <NavLink to={`/shopitems/${post.sellercode}`}>
                      {post.sellername}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="">{post.packagetype}</NavLink>
                  </li>
                </ul>
                <div className="rating">
                  <i className="fa-solid fa-star"></i>
                  <i className="fa-solid fa-star"></i>
                  <i className="fa-solid fa-star"></i>
                  <i className="fa-solid fa-star"></i>
                  <i className="fa-solid fa-star"></i>
                </div>
                {/* <span>Discounted : 20%</span> */}
                {/* <div className="progress">
                  <div
                    className="progress-bar w-50"
                    role="progressbar"
                    aria-valuenow="50"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div> */}
              </div>

                <div className={`${view || 'col-3 col-xs-2'} content-bottom`}>
                  <h4 className="price"> GH₵ {Number(post.price).toFixed(2)}</h4>
                  {/* <p onClick={() => addToCart(post)} style={{ cursor: "pointer", padding: "5px 10px" }}>
                    <span>Add To Cart</span>
                  </p> */}
                </div>

            </div>
          </div>
        ))}
      </>
    );
  }
}

export default Productbrandlist;
