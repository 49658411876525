import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import { NavLink, useParams } from "react-router-dom";
import { API_BASE_URL } from "../config/connect";
import Bannerad from "./Bannerad";
import Productbrandlist from "../components/Productbrandlist";
import Header from "../components/Header";
import Trendingproducts from "../components/Trendingproducts";
import Pagination from "../components/Pagination";
import { useProductData } from "../components/ProductsContextApi";
import Filtering from "../components/Filtering";
import Automobile from "../components/AutomobileSearch";
import useFetch from "../hooks/useFetch";

function Products() {
  const { brandcode } = useParams();

  const [isGrid, setIsGrid] = useState(true);
  const [showCarFilters, setCarFilters] = useState(false);

  // const { data: products, loading: loading } = useFetch(
  //   `https://ghmarkets.net/test/ghmarketconnect/?ghw=productservices&svg=list&brd=${brandcode}`,
  //   {},
  //   [],
  //   null
  // );

  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [getproduct, setGetProduct] = useState([]);
  // const [getloading, setGetProduct] = useState([]);

  useEffect(() => {
    setLoading(true);
    const getProducts = () => {
      try {
        fetch(
          `https://ghmarkets.net/test/ghmarketconnect/?ghw=productservices&svg=list&brd=${brandcode}`
        )
          .then((response) => response.json())
          .then((json) => {
            setProducts(json["results"]);
            setLoading(false);
          });
      } catch (error) {
        console.error("Internet Connection Down", error);
        throw error;
      }
    };

    const getBrand = () => {
      try {
        fetch(
          `https://ghmarkets.net/test/ghmarketconnect/?ghw=productservices&svg=catandsub&brcode=${brandcode}`
        )
          .then((response) => response.json())
          .then((json) => {
            setGetProduct(json["results"]);
          });
      } catch (error) {
        console.error("Internet Connection Down", error);
        throw error;
      }
    };

    getProducts();
    getBrand();
  }, [brandcode]);

  // const { data: getproduct, loading: getloading } = useFetch(
  //   `https://ghmarkets.net/test/ghmarketconnect/?ghw=productservices&svg=catandsub&brcode=${brandcode}`,
  //   {},
  //   [],
  //   null
  // );
  // // console.log(getproduct?.[0]?.categorycode);
  // // console.log(getproduct?.[0]?.subcategorycode);
  const catcode = getproduct?.[0]?.categorycode;
  const subcode = getproduct?.[0]?.subcategorycode;

  // const allprods = useProductData().allProductData;
  // const products = useMemo(() => allprods?.filter(
  //   (product) => product.brand === brandcode && product.bidstender == "0"
  // ), [allprods, brandcode]);
  // console.log(products)
  const [filteredProducts, setFilteredProducts] = useState(products);

  //console.log(products);

  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(9);

  // Function to handle filtering when the button is clicked
  const applyFilter = useCallback(
    (searchText, minPrice, maxPrice) => {
      const filtered = products?.filter((product) => {
        const productName = product.name?.toLowerCase() || "";
        const productDescription = product.description?.toLowerCase() || "";
        const productModel = product.model?.toLowerCase() || "";
        const productCategoryName = product.categoryname?.toLowerCase() || "";
        const productSubCategoryName =
          product.subcategoryname?.toLowerCase() || "";
        const productBrandName = product.brandname?.toLowerCase() || "";

        const searchTextMatch =
          productName.includes(searchText.toLowerCase()) ||
          productDescription.includes(searchText.toLowerCase()) ||
          productModel.includes(searchText.toLowerCase()) ||
          productCategoryName.includes(searchText.toLowerCase()) ||
          productSubCategoryName.includes(searchText.toLowerCase()) ||
          productBrandName.includes(searchText.toLowerCase());

        let prodPrice = product.price == null ? 0 : product.price;
        minPrice = isNaN(minPrice) ? 0 : minPrice;

        const priceMatch =
          (!minPrice || parseFloat(prodPrice) >= parseFloat(minPrice)) &&
          (!maxPrice || parseFloat(prodPrice) <= parseFloat(maxPrice));

        return searchTextMatch && priceMatch;
      });

      setFilteredProducts(filtered);
      setCurrentPage(1); // Reset to first page after filtering
    },
    [products]
  );

  // // Function to handle filtering when the button is clicked
  const applyFilterAuto = (make, model, year, minPrice, maxPrice) => {
    const filtered = products.filter((product) => {
      const productMake = product.make || "";
      const productModel = product.model || "";
      const productYear = product.year || "";

      const productPrice = parseFloat(product.price);

      // Check if the product matches the filters
      const makeMatch =
        !make || productMake.toLowerCase().includes(make.toLowerCase());
      const modelMatch =
        !model || productModel.toLowerCase().includes(model.toLowerCase());
      const yearMatch = !year || productYear.includes(year);

      const priceMatch =
        (!minPrice || productPrice >= parseFloat(minPrice)) &&
        (!maxPrice || productPrice <= parseFloat(maxPrice));

      return makeMatch && modelMatch && yearMatch && priceMatch;
    });

    setFilteredProducts(filtered);
    setCurrentPage(1);
  };

  useEffect(() => {
    // Check categorycode and set car filters
    if (!loading) {
      if (
        products[0]?.categorycode === "CAT654b8b6b71b9b" &&
        products[0]?.subcategorycode === "SCAT654b8e769f1ac"
      ) {
        setCarFilters(true);
      } else if (
        products[0]?.categorycode === undefined &&
        products[0]?.subcategorycode === undefined
      ) {
        // console.log(catcode);
        // console.log(subcode);
        if (catcode === "CAT654b8b6b71b9b" && subcode === "SCAT654b8e769f1ac") {
          setCarFilters(true);
        } else {
          setCarFilters(false);
        }
      } else {
        setCarFilters(false);
      }
    }
  }, [brandcode, loading]);

  // Get current posts
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = filteredProducts?.slice(
    indexOfFirstPost,
    indexOfLastPost
  );

  const paginate = (data) => {
    let currentNumber = data.selected + 1;
    setCurrentPage(currentNumber);
  };
  //Pagination data ends here

  const toggleView = (viewType) => {
    setIsGrid(viewType);
  };

  return (
    <div>
      <Header />

      <main>
        <Bannerad />

        <div className="shop-area pt-90 pb-90">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-3 col-lg-4 col-md-8 col-sm-10 order-2 order-lg-0">
                <aside className="shop-sidebar">
                  {showCarFilters ? (
                    <Automobile
                      onFilterChange={applyFilterAuto}
                      data={products}
                    />
                  ) : (
                    <Filtering
                      onFilter={applyFilter} // Pass the applyFilter function
                      data={products} // Pass the products data to the Filtering component
                    />
                  )}

                  <Trendingproducts>Service Providers</Trendingproducts>
                </aside>
              </div>
              <div className="col-xl-9 col-lg-8">
                <div className="shop-top-wrap mb-35">
                  <div className="shop-top-left">
                    {/* <h5 className="title">Shop</h5> */}
                  </div>
                  <div className="shop-top-right">
                    <form action="#">
                      <label htmlFor="shortBy">sort By</label>
                      {/* <select id="shortBy" name="select" className="form-select" aria-label="Default select example">
                                            <option value="">Sorting</option>
                                            <option>Free Shipping</option>
                                            <option>Best Match</option>
                                            <option>Newest Item</option>
                                            <option>Size A - Z</option>
                                        </select> */}
                    </form>
                    <ul>
                      <li>View</li>
                      <li
                        className={isGrid && "active"}
                        onClick={() => {
                          toggleView(true);
                        }}
                      >
                        <NavLink to="">
                          <i className="fa-solid fa-table-cells"></i>
                        </NavLink>
                      </li>
                      <li
                        className={isGrid || "active"}
                        onClick={() => {
                          toggleView(false);
                        }}
                      >
                        <NavLink to="">
                          <i className="fa-solid fa-bars"></i>
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="row">
                  <Productbrandlist
                    slicedPosts={currentPosts}
                    loading={loading}
                    view={isGrid}
                  />
                </div>
                <div className="shop-bottom-wrap">
                  {filteredProducts?.length > 0 ? (
                    <div className="shop-bottom-top">
                      {/* <h5 className="title">Shop</h5> */}
                      <p>
                        Showing {indexOfFirstPost + 1} to{" "}
                        {indexOfLastPost > filteredProducts.length
                          ? filteredProducts.length
                          : indexOfLastPost}{" "}
                        of {filteredProducts.length} Item(s)
                      </p>
                      <Pagination
                        postsPerPage={postsPerPage}
                        totalPosts={filteredProducts.length}
                        paginate={paginate}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="shop-bottom-box">
                    <div className="shop-bottom-left">
                      <form action="#">
                        {/* <select id="short-By" name="select" className="form-select" aria-label="Default select example">
                                                <option value="">Show 09</option>
                                                <option>Show 12</option>
                                                <option>Show 08</option>
                                                <option>Show 06</option>
                                                <option>Show 03</option>
                                            </select> */}
                      </form>
                    </div>
                    <div className="shop-bottom-right">
                      <form action="#">
                        {/* <select id="short-by" name="select" className="form-select" aria-label="Default select example">
                                                <option value="">Default sorting</option>
                                                <option>Free Shipping</option>
                                                <option>Best Match</option>
                                                <option>Newest Item</option>
                                                <option>Size A - Z</option>
                                            </select> */}
                      </form>
                      <ul>
                        <li
                          className={isGrid && "active"}
                          onClick={() => {
                            toggleView(true);
                          }}
                        >
                          <NavLink to="#">
                            <i className="fa-solid fa-table-cells"></i>
                          </NavLink>
                        </li>
                        <li
                          className={isGrid || "active"}
                          onClick={() => {
                            toggleView(false);
                          }}
                        >
                          <NavLink to="#">
                            <i className="fa-solid fa-bars"></i>
                          </NavLink>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default Products;
