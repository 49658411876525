import { createSlice } from "@reduxjs/toolkit";

const initialState = { products: [] };

const wishlistSlice = createSlice({
  name: "wishlist",
  initialState,
  reducers: {
    addWishList: (state, action) => {
      // check if product already exist in wishlist
      const proudctInWishList = state.products.find(
        (product) => product.prodcode === action.payload.prodcode
      );

      // console.log("proudctInWishList...");
      // console.log(state);

      if (proudctInWishList) {
        // proudctInWishList.quantity += 1;
        // total = proudctInWishList.quantity * proudctInWishList.price;
      } else {
        // total = 1 * action.payload.price;
        state.products.push({ ...action.payload });
      }

      //   state.productsNumber += 1;
      //   state.productsAmt += total;

      localStorage.setItem("wishlist", JSON.stringify(state));
      // console.log(state);
      // console.log(action);
    },

    removeWishList: (state, action) => {
      // check if the product exist
      const productToRemove = state.products.find(
        (product) => product.prodcode === action.payload
      );

      // find index of the product removing
      const index = state.products.findIndex(
        (product) => product.prodcode === action.payload
      );
      // remove product from array
      state.products.splice(index, 1);

      localStorage.setItem("wishlist", JSON.stringify(state));
    },
  },
});

export const { addWishList, removeWishList } = wishlistSlice.actions;
export default wishlistSlice.reducer;
