import React from "react";
import { NavLink } from "react-router-dom";

function Navbar() {
    return (
			<div>
				<div className='navbar-wrap main-menu d-none d-lg-flex'>
					<ul className='navigation'>
						<li className='active menu-item-has-children'>
							<NavLink to='/'> Home </NavLink>{' '}
						</li>{' '}
						<li className='menu-item-has-children'>
							<NavLink to=''> SERVICES </NavLink>{' '}
							<ul className='submenu'>
								<li>
									<NavLink to='/profservices'> Professional Services </NavLink>{' '}
								</li>{' '}
								<li>
									<NavLink to='/bluecollar'> Artisanal Services </NavLink>{' '}
								</li>{' '}
							</ul>{' '}
						</li>{' '}
						<li className='menu-item-has-children'>
							<NavLink to='/commodities'> AGRIC COMMODITIES </NavLink>{' '}
						</li>{' '}
						<li>
							<NavLink to='/productslist'> BIDS & TENDER </NavLink>{' '}
						</li>{' '}
						<li>
							<NavLink to='/blackfriday'> BLACK FRIDAY </NavLink>{' '}
						</li>
					</ul>{' '}
				</div>{' '}
			</div>
		);
}

export default Navbar;