import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { API_BASE_URL } from "../../config/connect";

const Uhd = ({ onUhdSelected }) => {
  const [SelectedUhd, setSelectedUhd] = useState(null);
  const [variations, setVariations] = useState([]);
  const { prodcode } = useParams();

  const getAllProductVariations = async () => {
    try {
      const response = await fetch(
        `https://ghmarkets.net/test/ghmarketconnect/?ghw=productservices&svg=fashionvariations&prcode=${prodcode}`
      );
      const json = await response.json();
      const firstResult = json["results"][0];
      if (firstResult && firstResult.uhd) {
        const uhdObject = firstResult.uhd;
        if (uhdObject.trim() !== "") {
          setVariations(uhdObject.split(","));
        } else {
        }
      } else {
      }
    } catch (error) {
      // console.error("Error fetching product variations:", error);
      // Handle error here
    }
  };

  useEffect(() => {
    getAllProductVariations();
  }, [prodcode]);

  //get variation selected and send it to the cart
  const handleColorClick = (size) => {
    setSelectedUhd(size);
    onUhdSelected(size);
  };

  return variations.length > 0 ? (
    <div
      className="sizes-container"
      style={{ display: "flex", flexDirection: "row" }}
    >
      <span
        style={{
          fontSize: "",
          fontWeight: "bold",
          color: "black",
          marginTop: "12px",
        }}
      >
        Uhd:
      </span>
      <div className="sizes">
        {variations.map((variation, i) => (
          <div
            key={i}
            className={`size ${SelectedUhd === variation ? "selected" : ""}`}
            onClick={() => handleColorClick(variation)}
          >
            {variation}
          </div>
        ))}
      </div>
    </div>
  ) : (
    ""
  );
};

export default Uhd;
