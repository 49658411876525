import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { NavLink, useParams } from "react-router-dom";
// import sidebar_product01 from "../assets/img/product/sidebar_product01.jpg";
// import sidebar_product02 from "../assets/img/product/sidebar_product02.jpg";
// import sidebar_product03 from "../assets/img/product/sidebar_product03.jpg";
import Header from "../components/Header";
import Pagination from "../components/Pagination";
import { API_BASE_URL } from "../config/connect";
import { add } from "../store/cartSlice";
import Trendingproducts from "../components/Trendingproducts";

function Shopitems() {
  const { shopcode } = useParams();
  const [products, setProducts] = useState("");
  const [shopname, setShopname] = useState("");
  const [shopclass, setShopclass] = useState("");
  const dispatch = useDispatch();

  const getAllProducts = () => {
    fetch(
      `https://ghmarkets.net/test/ghmarketconnect/?ghw=shopitemslist&svg=list&shpcd=${shopcode}`
    )
      .then((response) => response.json())
      .then((json) => {
        // console.log(json["results"]);
        setProducts(json["results"].slice(0, 200));
        setShopname(json["results"][0]["sellername"]);
        setShopclass(json["results"][0]["classify"]);
      });
  };

  useEffect(() => {
    getAllProducts();
  }, []);

  const addToCart = (product) => {
    // dispatch an add action
    dispatch(add(product));
  };

  /**
   * Pagination data starts here
   */
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(9);

  // Get current posts
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = products.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (data) => {
    let currentNumber = data.selected + 1;
    setCurrentPage(currentNumber);
  };
  //Pagination data ends here

  return (
    <div>
      <Header />

      <main>
        <div className="shop-area pt-90 pb-90">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-3 col-lg-4 col-md-8 col-sm-10 order-2 order-lg-0">
                <aside className="shop-sidebar">
                  <Trendingproducts>Service Providers</Trendingproducts>
                </aside>
              </div>
              <div className="col-xl-9 col-lg-8">
                <div className="shop-top-wrap mb-35">
                  <div className="shop-top-left">
                    <h5 className="title">
                      Shop Name: {shopname}
                      {shopclass === "1" && (
                        <i
                          className="fa fa-check-circle"
                          style={{ marginLeft: "5px", color: "#F6921E" }}
                        ></i>
                      )}
                      {shopclass === "2" && (
                        <i
                          className="fa fa-star"
                          style={{ marginLeft: "5px", color: "#F6921E" }}
                        ></i>
                      )}
                    </h5>
                  </div>
                  <div className="shop-top-right">
                    <form action="#">
                      <label htmlFor="shortBy">All Items</label>
                      {/* <select id="shortBy" name="select" className="form-select" aria-label="Default select example">
                                          <option value="">Sorting</option>
                                          <option>Free Shipping</option>
                                          <option>Best Match</option>
                                          <option>Newest Item</option>
                                          <option>Size A - Z</option>
                                      </select> */}
                    </form>
                    {/* <ul>
                    <li>View</li>
                    <li className="active">
                      <NavLink to="">
                        <i className="fa-solid fa-table-cells"></i>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="">
                        <i className="fa-solid fa-bars"></i>
                      </NavLink>
                    </li>
                  </ul> */}
                  </div>
                </div>
                <div className="row justify-content-center">
                  {currentPosts &&
                    currentPosts.map((post, index) => {
                      return (
                        <div
                          key={index}
                          className="col-xl-4 col-lg-6 col-md-6 col-sm-8"
                        >
                          <div className="shop-product-item mb-30">
                            <div className="shop-thumb">
                              <NavLink to={`/shopdetail/${post.code}`}>
                                <img
                                  src={post.previewpicture || post.picture}
                                  alt="img"
                                  width="250px"
                                  height="200px"
                                />

                                {post.freedelivery === "1" && (
                                  <div
                                    style={{
                                      position: "absolute",
                                      top: "1px", // Adjust the position as needed
                                      right: "3px", // Change this to `left: "10px"` for the left corner
                                      backgroundColor: "#F6921E", // Orange background
                                      color: "white", // White text color
                                      padding: "1px 10px", // Padding for better appearance
                                      fontSize: "12px", // Adjust text size as needed
                                      borderRadius: "5px", // Rounded corners
                                      height: "20px",
                                    }}
                                  >
                                    Free Delivery
                                  </div>
                                )}
                              </NavLink>
                              {/* <span>Premium</span> */}
                            </div>
                            <div className="shop-content">
                              <h2 className="title">
                                <NavLink to={`/shopdetail/${post.code}`}>
                                  {post.name}
                                </NavLink>
                              </h2>
                              <ul className="tag">
                                <li>
                                  Packaging{" "}
                                  {/* <NavLink to="vendor-profile.html"> */}
                                  {post.packagetype}
                                  {/* </NavLink> */}
                                </li>
                              </ul>
                              <div className="rating d-flex">
                                <div>
                                  {/* Generate 5 star icons with yellow color for the rated stars */}
                                  {Array.from({ length: 5 }, (_, index) => (
                                    <i
                                      key={index}
                                      className="fa-solid fa-star"
                                      style={{
                                        color:
                                          index < Math.floor(post.ratingaverage)
                                            ? "#ffc107"
                                            : "#e4e5e9",
                                      }}
                                    ></i>
                                  ))}
                                </div>
                                <div>
                                  <h6
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "12px",
                                      marginLeft: "3px",
                                      marginTop: "4px",
                                    }}
                                  >
                                    ({post.ratingcount})
                                  </h6>
                                </div>
                              </div>
                              {/* <div className="rating"> */}
                              {/* <i className="fa-solid fa-star"></i>
                                    <i className="fa-solid fa-star"></i>
                                    <i className="fa-solid fa-star"></i>
                                    <i className="fa-solid fa-star"></i>
                                    <i className="fa-solid fa-star"></i> */}
                              {/* <p><RatingPerItem productCode={post.code} margin="5px"/></p> */}
                              {/* </div> */}
                              {/* <div className="progress">
                                <div
                                  className="progress-bar w-100"
                                  role="progressbar"
                                  aria-valuenow="50"
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                ></div>
                              </div> */}
                              <div className="content-bottom">
                                <h4 className="price">
                                  {post.price
                                    ? `GH₵ ${Number(post.price).toFixed(2).toLocaleString()}`
                                    : ""}
                                </h4>

                                <p
                                  onClick={() => addToCart(post)}
                                  style={{
                                    cursor: "pointer",
                                    padding: "5px 10px",
                                  }}
                                >
                                  {/* <span>Add To Cart</span> */}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
                <div className="shop-bottom-wrap">
                  {products.length > 0 ? (
                    <div className="shop-bottom-top">
                      <h5 className="title">Shop</h5>
                      <p>
                        Showing {indexOfFirstPost + 1} to{" "}
                        {indexOfLastPost > products.length
                          ? products.length
                          : indexOfLastPost}{" "}
                        of {products.length} Item(s)
                      </p>
                      <Pagination
                        postsPerPage={postsPerPage}
                        totalPosts={products.length}
                        paginate={paginate}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="shop-bottom-box">
                    <div className="shop-bottom-left">
                      <form action="#">
                        {/* <select id="short-By" name="select" className="form-select" aria-label="Default select example">
                                              <option value="">Show 09</option>
                                              <option>Show 12</option>
                                              <option>Show 08</option>
                                              <option>Show 06</option>
                                              <option>Show 03</option>
                                          </select> */}
                      </form>
                    </div>
                    <div className="shop-bottom-right">
                      <form action="#">
                        {/* <select id="short-by" name="select" className="form-select" aria-label="Default select example">
                                              <option value="">Default sorting</option>
                                              <option>Free Shipping</option>
                                              <option>Best Match</option>
                                              <option>Newest Item</option>
                                              <option>Size A - Z</option>
                                          </select> */}
                      </form>
                      <ul>
                        <li className="active">
                          <NavLink to="#">
                            <i className="fa-solid fa-table-cells"></i>
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="#">
                            <i className="fa-solid fa-bars"></i>
                          </NavLink>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default Shopitems;
