import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { API_BASE_URL } from "../../config/connect";

const ScreenSize = ({ onScreenSizeSelected }) => {
  const [selectedScreenSize, setSelectedScreenSize] = useState(null);
  const [variations, setVariations] = useState([]);
  const { prodcode } = useParams();

  const getAllProductVariations = async () => {
    try {
      const response = await fetch(
        `https://ghmarkets.net/test/ghmarketconnect/?ghw=productservices&svg=fashionvariations&prcode=${prodcode}`
      );
      const json = await response.json();
      const firstResult = json["results"][0];
      if (firstResult && firstResult.screensize) {
        const screenSizeObject = firstResult.screensize;
        if (screenSizeObject.trim() !== "") {
          setVariations(screenSizeObject.split(","));
        }
      }
    } catch (error) {
      console.error("Error fetching product variations:", error);
    }
  };

  useEffect(() => {
    getAllProductVariations();
  }, [prodcode]);

  const handleScreenSizeClick = (screensize) => {
    setSelectedScreenSize(screensize);
    onScreenSizeSelected(screensize);
  };

  return variations.length > 0 ? (
    <div
      className="sizes-container"
      style={{ display: "flex", flexDirection: "row" }}
    >
      <span
        style={{
          fontSize: "",
          fontWeight: "bold",
          color: "black",
          marginTop: "12px",
        }}
      >
        Screen Size:
      </span>
      <div className="sizes">
        {variations.map((variation, i) => (
          <div
            key={i}
            className={`size ${selectedScreenSize === variation ? "selected" : ""}`}
            onClick={() => handleScreenSizeClick(variation)}
          >
            {variation}
          </div>
        ))}
      </div>
    </div>
  ) : (
    ""
  );
};

export default ScreenSize;
