import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import { API_BASE_URL } from "../../config/connect";
import SizeGuideModal from "../SizeGuideModal";

const Sizes = ({ onSizeSelected }) => {
  const [selectedSize, setSelectedSize] = useState(null);
  const [variations, setVariations] = useState([]);
  const { prodcode } = useParams();
  const [isSizeGuideOpen, setIsSizeGuideOpen] = useState(false);

  const handleOpenSizeGuide = () => {
    setIsSizeGuideOpen(true);
  };

  const handleCloseSizeGuide = () => {
    setIsSizeGuideOpen(false);
  };

  const getAllProductVariations = async () => {
    try {
      const response = await fetch(
        `https://ghmarkets.net/test/ghmarketconnect/?ghw=productservices&svg=fashionvariations&prcode=${prodcode}`
      );
      const json = await response.json();
      const firstResult = json["results"][0];
      if (firstResult && firstResult.size) {
        const sizeObject = firstResult.size;
        if (sizeObject.trim() !== "") {
          setVariations(sizeObject.split(","));
        } else {
        }
      } else {
      }
    } catch (error) {
      // console.error("Error fetching product variations:", error);
      // Handle error here
    }
  };

  useEffect(() => {
    getAllProductVariations();
  }, [prodcode]);

  //get variation selected and send it to the cart
  const handleSizeClick = (size) => {
    setSelectedSize(size);
    onSizeSelected(size);
  };

  return variations.length > 0 ? (
    <div
      className="sizes-container"
      style={{ display: "flex", flexDirection: "row" }}
    >
      <span
        className="title"
        style={{
          fontSize: "",
          fontWeight: "bold",
          color: "black",
          marginTop: "12px",
        }}
      >
        Sizes:
      </span>{" "}
      <div className="sizes">
        {" "}
        {variations.map((variation, i) => (
          <div
            key={i}
            className={`size ${selectedSize === variation ? "selected" : ""}`}
            onClick={() => handleSizeClick(variation)}
          >
            {variation}{" "}
          </div>
        ))}{" "}
      </div>{" "}
      <div style={{ marginTop: "12px", marginLeft: "15px" }}>
        <NavLink className="size-guide" onClick={handleOpenSizeGuide}>
          Size Guide{" "}
        </NavLink>{" "}
      </div>{" "}
      <SizeGuideModal
        isOpen={isSizeGuideOpen}
        onRequestClose={handleCloseSizeGuide}
      />{" "}
    </div>
  ) : (
    ""
  );
};

export default Sizes;
