import React from 'react';
import {NavLink } from "react-router-dom";
// import bannerpic1 from "../assets/img/product/br_product_img.png";
import banner1 from "../assets/img/slider/banner1.jpeg";

function Bannerad() {
  return (
    <div>

{/* <section className="breadcrumb-area breadcrumb-bg"> */}
<section className="breadcrumb-area">
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                    {/* <img src={banner1} alt="AfricaMarkets" height="330px" /> */}
                        {/* <div className="col-xl-3 col-lg-4 col-md-5 col-sm-8 order-2 order-md-0">
                            <div className="breadcrumb-product text-center">
                                <div className="thumb">
                                    <NavLink to="shop-details.html">
                                    <img src={bannerpic1} alt="bannerpic1" />
                                    </NavLink>
                                    <span>35% OFF</span>
                                </div>
                                <div className="content">
                                    <div className="rating">
                                        <i className="fa-solid fa-star"></i>
                                        <i className="fa-solid fa-star"></i>
                                        <i className="fa-solid fa-star"></i>
                                        <i className="fa-solid fa-star"></i>
                                        <i className="fa-solid fa-star"></i>
                                    </div>
                                    <h4 className="title"><NavLink to="">Blender Mixer Food</NavLink></h4>
                                    <h5 className="price">$37.00</h5>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-9 col-lg-8 col-md-7">
                            <div className="breadcrumb-content">
                                <h2 className="title">Discount shop</h2>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><NavLink to="/">Home</NavLink></li>
                                        <li className="breadcrumb-item active" aria-current="page">Shop</li>
                                    </ol>
                                </nav>
                            </div>
                        </div> */}
                    </div>
                </div>
            </section>


    </div>
  )
}

export default Bannerad