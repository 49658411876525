import { NavLink } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
// import { API_BASE_URL } from "../config/connect";
import { useProductData } from './ProductsContextApi';

function AdsSlids() {
	const AdsSlids = useProductData().AdsSlids;
	const [visibleAds, setVisibleAds] = useState(AdsSlids.slice(0, 4));

	useEffect(() => {
		const intervalId = setInterval(() => {
			setVisibleAds((prevVisibleAds) => {
				const startIndex =
					(AdsSlids.indexOf(prevVisibleAds[0]) + 4) % AdsSlids.length;

				const nextAds = AdsSlids.slice(startIndex, startIndex + 4);

				return nextAds.length === 4
					? nextAds
					: [...nextAds, ...AdsSlids.slice(0, 4 - nextAds.length)];
			});
		}, 15000);

		return () => clearInterval(intervalId);
	}, [AdsSlids]);

	return (
		<div
			id='adCarousel'
			className='carousel slide'
			data-ride='carousel'>
			<div className='carousel-inner'>
				<div className='carousel-item active'>
					<div className='row'>
						{visibleAds.map((ad, index) => (
							<div
								key={index}
								className='col-6 col-md-4 col-lg-3 mb-4'>
								<div className='product-card'>
									<NavLink to={`shopdetail/${ad.code}`}>
										<img
											src={ad.picture}
											alt={ad.title || `Ad ${index + 1}`}
											className='img-fluid uniform-image'
										/>
									</NavLink>
									<div className='product-info'>
										<NavLink to={`shopdetail/${ad.code}`}>
											<h5 className='brand-name'>
												{ad.name || 'Unknown Brand'}
											</h5>
											<p className='price'>GH₵{ad.price || 'GH₵'}</p>
										</NavLink>
									</div>
								</div>
							</div>
						))}
					</div>
				</div>
			</div>
		</div>
	);
}
export default AdsSlids;
