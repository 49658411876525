import React from 'react'
import slider_img01 from "../assets/img/banner/comingsoon.jpeg";

function GeneralInfoPopUp() {
  return (
    <div>
       <img src={slider_img01} alt="Coming Soon" width="1500px" height="700px"/>
    </div>
  )
}

export default GeneralInfoPopUp