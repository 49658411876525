import React, { useState, useEffect } from "react";
import { API_BASE_URL } from "../config/connect";
import { NavLink } from "react-router-dom";
import RatingPerItem from "./RatingPerItem";
import Tooltip from "@mui/material/Tooltip";
import ProductSkeletonLoader from "./ProductSkeletonLoader";
import useFetch from "../hooks/useFetch";

function Superdeals() {
  // const [deals, setSuperdeals] = useState("");
  //const [topselection, setTopSelection] = useState("");
  //const [newarrivals, setNewArrivals] = useState("");

  const { data, loading, error } = useFetch(
    `https://ghmarkets.net/test/ghmarketconnect/?ghw=productservices&svg=superdeals&cls=min`,
    {},
    [],
    null
  );

  const {
    data: topdata,
    loading: toploading,
    error: toperror,
  } = useFetch(
    `https://ghmarkets.net/test/ghmarketconnect/?ghw=productservices&svg=topselection&cls=min`,
    {},
    [],
    null
  );

  const {
    data: newarrivals,
    loading: arrivalsloading,
    error: arrivalserror,
  } = useFetch(
    `https://ghmarkets.net/test/ghmarketconnect/?ghw=productservices&svg=newarrivals&cls=min`,
    {},
    [],
    null
  );
  const something = data?.slice(0, 6);
  // const getAllSuperDeals = () => {
  //   fetch(`https://ghmarkets.net/test/ghmarketconnect/?ghw=productservices&svg=superdeals&cls=min`)
  //     .then((response) => response.json())
  //     .then((json) => {
  //       // console.log(json["results"]);
  //       setSuperdeals(json["results"].slice(0, 6));
  //     });
  // };

  // const getTopSelection = () => {
  //   fetch(`https://ghmarkets.net/test/ghmarketconnect/?ghw=productservices&svg=topselection&cls=min`)
  //     .then((response) => response.json())
  //     .then((json) => {
  //       // console.log(json["results"]);
  //       setTopSelection(json["results"].slice(0, 3));
  //     });
  // };

  // const getNewArrivals = () => {
  //   fetch(`https://ghmarkets.net/test/ghmarketconnect/?ghw=productservices&svg=newarrivals&cls=min`)
  //     .then((response) => response.json())
  //     .then((json) => {
  //       // console.log(json["results"]);
  //       setNewArrivals(json["results"].slice(0, 3));
  //     });
  // };

  // useEffect(() => {
  //   // getAllSuperDeals();
  //   // getTopSelection();
  //  // getNewArrivals();
  // }, []);

  const TruncateString = ({ text, maxLength }) => {
    const truncatedText =
      text.length > maxLength ? text.slice(0, maxLength) + "..." : text;

    return <h6>{truncatedText}</h6>;
  };
  return (
    <div>
      <section className="super-deals-product-area pt-30 pb-10 ">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="product-wrap mb-30">
                <div className="row align-items-center mb-20">
                  <div className="col-xl-3 col-lg-4 col-md-3">
                    <div className="section-title">
                      <h2 className="title">
                        Super <span>Deals</span>
                      </h2>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-7">
                    <div className="super-deals-countdown">
                      {/* <p>Top products. Incredible prices</p> */}
                      <div
                        className="coming-time"
                        data-countdown="2023/4/30"
                      ></div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-2 col-md-2">
                    <div className="view-more text-end">
                      <NavLink to="/viewmore/superdeals">View more</NavLink>
                    </div>
                  </div>
                </div>
                <div className="row custom justify-content-center">
                  {!loading ? (
                    something?.map((deal) => {
                      return (
                        <div
                          className="col-xl-2 col-lg-3 col-md-4 col-sm-6 superdeals"
                          key={deal.id}
                        >
                          <div className="product-item mb-30">
                            <div
                              className="product-thumb"
                              style={{ position: "relative" }}
                            >
                              <NavLink to={`/shopdetail/${deal.code}`}>
                                <img
                                  src={deal.previewpicture || deal.picture}
                                  alt=""
                                  style={{
                                    height: "200px",
                                    objectFit: "cover",
                                  }}
                                />
                                {deal.freedelivery === "1" && (
                                  <div
                                    style={{
                                      position: "absolute",
                                      top: "1px", // Adjust the position as needed
                                      right: "3px", // Change this to `left: "10px"` for the left corner
                                      backgroundColor: "#F6921E", // Orange background
                                      color: "white", // White text color
                                      padding: "1px 10px", // Padding for better appearance
                                      fontSize: "12px", // Adjust text size as needed
                                      borderRadius: "5px", // Rounded corners
                                      height: "20px",
                                    }}
                                  >
                                    Free Delivery
                                  </div>
                                )}
                              </NavLink>
                            </div>
                            <div className="product-content">
                              <h4 className="title">
                                <Tooltip
                                  title={
                                    <span
                                      style={{
                                        color: "white",
                                        fontSize: "13px",
                                        display: "inline-block",
                                      }}
                                    >
                                      {deal.name}
                                    </span>
                                  }
                                  arrow
                                >
                                  <NavLink to={`/shopdetail/${deal.code}`}>
                                    <p>
                                      <TruncateString
                                        text={deal.name}
                                        maxLength={25}
                                      />
                                    </p>

                                    {` GH₵${Number(deal.price).toFixed(2).toLocaleString()}`}
                                  </NavLink>
                                </Tooltip>
                              </h4>
                              <div className="rating d-flex">
                                <div>
                                  {Array.from({ length: 5 }, (_, index) => (
                                    <i
                                      key={index}
                                      className="fa-solid fa-star"
                                      style={{
                                        color:
                                          index < Math.floor(deal.ratingaverage)
                                            ? "#ffc107"
                                            : "#e4e5e9",
                                      }}
                                    ></i>
                                  ))}
                                </div>
                                <div>
                                  <h6
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "12px",
                                      marginLeft: "3px",
                                      marginTop: "4px",
                                    }}
                                  >
                                    ({deal.ratingcount})
                                  </h6>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <ProductSkeletonLoader height={"200px"} length={6} xl={2} />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-xl-6 col-lg-9">
              <div className="product-wrap top-product mb-20">
                <div className="row mb-20">
                  <div className="col-sm-6">
                    <div className="product-title">
                      <h4 className="title">Top Selection</h4>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="view-more text-end">
                      <NavLink to="/viewmore/topselection">View more</NavLink>
                    </div>
                  </div>
                </div>
                <div className="row custom justify-content-center">
                  {!toploading ? (
                    topdata?.map((selecteditem) => {
                      return (
                        <div
                          className="col-md-4 col-sm-6"
                          key={selecteditem.id}
                        >
                          <div className="product-item mb-30">
                            <div
                              className="product-thumb"
                              style={{ position: "relative" }}
                            >
                              <NavLink to={`/shopdetail/${selecteditem.code}`}>
                                <img
                                  src={
                                    selecteditem.previewpicture ||
                                    selecteditem.picture
                                  }
                                  alt=""
                                  style={{
                                    height: "200px",
                                    objectFit: "cover",
                                  }}
                                />

                                {selecteditem.freedelivery === "1" && (
                                  <div
                                    style={{
                                      position: "absolute",
                                      top: "1px", // Adjust the position as needed
                                      right: "3px", // Change this to `left: "10px"` for the left corner
                                      backgroundColor: "#F6921E", // Orange background
                                      color: "white", // White text color
                                      padding: "1px 10px", // Padding for better appearance
                                      fontSize: "12px", // Adjust text size as needed
                                      borderRadius: "5px", // Rounded corners
                                      height: "20px",
                                    }}
                                  >
                                    Free Delivery
                                  </div>
                                )}
                              </NavLink>
                            </div>
                            <div className="product-content">
                              <h4 className="title">
                                <Tooltip
                                  title={
                                    <span
                                      style={{
                                        color: "white",
                                        fontSize: "13px",
                                        display: "inline-block",
                                      }}
                                    >
                                      {selecteditem.name}
                                    </span>
                                  }
                                  arrow
                                >
                                  <NavLink
                                    to={`/shopdetail/${selecteditem.code}`}
                                  >
                                    <p>
                                      <TruncateString
                                        text={selecteditem.name}
                                        maxLength={25}
                                      />
                                    </p>

                                    {` GH₵${Number(selecteditem.price).toFixed(2).toLocaleString()}`}
                                  </NavLink>
                                </Tooltip>
                              </h4>
                              <div className="rating d-flex">
                                <div>
                                  {/* Generate 5 star icons with yellow color for the rated stars */}
                                  {Array.from({ length: 5 }, (_, index) => (
                                    <i
                                      key={index}
                                      className="fa-solid fa-star"
                                      style={{
                                        color:
                                          index <
                                          Math.floor(selecteditem.ratingaverage)
                                            ? "#ffc107"
                                            : "#e4e5e9",
                                      }}
                                    ></i>
                                  ))}
                                </div>
                                <div>
                                  <h6
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "12px",
                                      marginLeft: "3px",
                                      marginTop: "4px",
                                    }}
                                  >
                                    ({selecteditem.ratingcount})
                                  </h6>
                                </div>
                              </div>
                              {/* <p>
                                <RatingPerItem
                                  productCode={selecteditem.code}
                                  margin="5px"
                                />
                              </p> */}
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <ProductSkeletonLoader height={"200px"} length={3} lg={4} />
                  )}
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-9">
              <div className="product-wrap top-product mb-20">
                <div className="row mb-20">
                  <div className="col-sm-6">
                    <div className="product-title">
                      <h4 className="title">New arrivals</h4>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="view-more text-end">
                      <NavLink to="/viewmore/newarrivals">View more</NavLink>
                    </div>
                  </div>
                </div>
                <div className="row custom justify-content-center">
                  {!arrivalsloading > 0 ? (
                    newarrivals?.map((newarrival) => {
                      return (
                        <div className="col-md-4 col-sm-6" key={newarrival.id}>
                          <div className="product-item mb-30">
                            <div
                              className="product-thumb"
                              style={{ position: "relative" }}
                            >
                              <NavLink to={`/shopdetail/${newarrival.code}`}>
                                <img
                                  src={
                                    newarrival.previewpicture ||
                                    newarrival.picture
                                  }
                                  alt=""
                                  style={{
                                    height: "200px",
                                    objectFit: "cover",
                                  }}
                                />

                                {newarrival.freedelivery === "1" && (
                                  <div
                                    style={{
                                      position: "absolute",
                                      top: "1px", // Adjust the position as needed
                                      right: "3px", // Change this to `left: "10px"` for the left corner
                                      backgroundColor: "#F6921E", // Orange background
                                      color: "white", // White text color
                                      padding: "1px 10px", // Padding for better appearance
                                      fontSize: "12px", // Adjust text size as needed
                                      borderRadius: "5px", // Rounded corners
                                      height: "20px",
                                    }}
                                  >
                                    Free Delivery
                                  </div>
                                )}
                              </NavLink>
                            </div>
                            <div className="product-content">
                              <h4 className="title">
                                <Tooltip
                                  title={
                                    <span
                                      style={{
                                        color: "white",
                                        fontSize: "13px",
                                        display: "inline-block",
                                      }}
                                    >
                                      {newarrival.name}
                                    </span>
                                  }
                                  arrow
                                >
                                  <NavLink
                                    to={`/shopdetail/${newarrival.code}`}
                                  >
                                    <p>
                                      <TruncateString
                                        text={newarrival.name}
                                        maxLength={25}
                                      />
                                    </p>

                                    {` ₵${Number(newarrival.price).toFixed(2).toLocaleString()}`}
                                  </NavLink>
                                </Tooltip>
                              </h4>
                              <div className="rating d-flex">
                                <div>
                                  {/* Generate 5 star icons with yellow color for the rated stars */}
                                  {Array.from({ length: 5 }, (_, index) => (
                                    <i
                                      key={index}
                                      className="fa-solid fa-star"
                                      style={{
                                        color:
                                          index <
                                          Math.floor(newarrival.ratingaverage)
                                            ? "#ffc107"
                                            : "#e4e5e9",
                                      }}
                                    ></i>
                                  ))}
                                </div>
                                <div>
                                  <h6
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "12px",
                                      marginLeft: "3px",
                                      marginTop: "4px",
                                    }}
                                  >
                                    ({newarrival.ratingcount})
                                  </h6>
                                </div>
                              </div>
                              {/* <p>
                                <RatingPerItem
                                  productCode={newarrival.code}
                                  margin="5px"
                                />
                              </p> */}
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <ProductSkeletonLoader height={"200px"} length={3} lg={4} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Superdeals;
