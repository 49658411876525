import React from 'react';
import { ToastContainer } from 'react-toastify';
import Header from '../components/Header';

const Privacy = () => {
	return (
		<>
			<Header />
			<section className='privacy-policy-section'>
				<div className='container'>
					<div className='row justify-content-center m-5'>
						<div className='col-lg-8'>
							<div className='card shadow p-4'>
								<div className='card-header text-white text-center'>
									<h5 className='mb-0'> Privacy Policy </h5>{' '}
								</div>{' '}
								<div className='card-body'>
									<p className='lead'>
									Africa Markets is committed to protecting the privacy of our users. This Privacy Policy explains how we collect, use, and disclose information about you when you use our website or services (collectively, the "Services"). By accessing or using our Services, you agree to this Privacy Policy.{' '}
									</p>{' '}
									<h2 className='text-center '>
									1. Information We Collect{' '}
										</h2>{' '}
									<p className='lead'>
									 
1.1 Information You Provide: We collect information that you voluntarily provide when you use our Services, such as when you create an account, submit forms, or communicate with us.		</p>{' '}
<p className='lead'>
1.2 Automatically Collected Information: When you access or use our Services, we automatically collect certain information, including your IP address, browser type, operating system, and other usage details.
</p>{' '}
<p className='lead'>
1.3 Cookies and Similar Technologies: We use cookies and similar technologies to collect information about your interactions with our Services and to improve your experience. You can control cookies through your browser settings. 
.
									</p>{' '}

									<h2 className='text-center '>
									2. How We Use Your Information {' '}
										</h2>{' '}
									<p className='lead'>
									 
									2.1 Providing and Improving the Services: We use the information we collect to provide, maintain, and improve our Services, as well as to develop new features and offerings. 		</p>{' '}
<p className='lead'>
2.2 Communications: We may use your information to communicate with you about our Services, including sending you service-related notices and updates. 
</p>{' '}
<p className='lead'>
2.3 Analytics: We use analytics tools to understand how users interact with our Services and to analyze trends and user preferences. 
.
									</p>{' '}

									<h2 className='text-center '>
									3. How We Share Your Information  {' '}
										</h2>{' '}
									<p className='lead'>
									 
									3.1 Service Providers: We may share your information with third-party service providers who assist us in providing the Services, such as hosting providers, payment processors, and analytics providers. 		</p>{' '}
<p className='lead'>
3.2 Legal Compliance: We may disclose your information to comply with applicable laws, regulations, or legal processes, or to respond to lawful requests from governmental authorities. 
</p>{' '}
<p className='lead'>
3.3 Business Transfers: If we are involved in a merger, acquisition, or sale of all or a portion of our assets, your information may be transferred as part of that transaction.  
.
									</p>{' '}

									<h2 className='text-center '>
									4. Your Choices   {' '}
										</h2>{' '}
									<p className='lead'>
									 
									4.1 Account Information: You may update or delete your account information by contacting us  	</p>{' '}
<p className='lead'>
4.2 Cookies: Most web browsers allow you to control cookies through their settings. However, disabling cookies may affect your ability to use certain features of the Services.  
</p>{' '}

<h2 className='text-center '>
5. Data Security {' '}
										</h2>{' '}
									<p className='lead'>
									 
									We take reasonable measures to protect your information from unauthorized access, use, or disclosure. However, no method of transmission over the internet or electronic storage is completely secure, so we cannot guarantee absolute security.  	</p>{' '}

									<h2 className='text-center '>
									6. Children's Privacy {' '}
										</h2>{' '}
									<p className='lead'>
									Our Services are not directed to children under the age of 13, and we do not knowingly collect personal information from children under the age of 13. If you believe that we have collected information from a child under the age of 13, please contact us immediately. </p>{' '}

									<h2 className='text-center '>
									7. Changes to This Privacy Policy {' '}
										</h2>{' '}
									<p className='lead'>
									 
									We may update this Privacy Policy from time to time. If we make material changes, we will notify you by posting the updated policy on our website or by other means. 	</p>{' '}


									
									{/* Add more content for the Privacy Policy */}{' '}
								</div>{' '}
							</div>{' '}
						</div>{' '}
					</div>{' '}
				</div>{' '}
			</section>{' '}
			<ToastContainer />
		</>
	);
};

export default Privacy;
