import React from "react";
import Skeleton from "@mui/material/Skeleton";
import Grid from "@mui/material/Grid";

const ViemoreProdSkeleton = () => {
  return (
    <>
      <Grid container spacing={3} style={{marginBottom:'15px'}}>
        {[...Array(3)].map((_, index) => (
          <Grid item xs={12} sm={6} md={4} lg={4}  key={index}>
            <Skeleton variant="rectangular" width="100%" height={"250px"} />
            <Skeleton variant="text" width="80%" height={20} />
            <Skeleton variant="text" width="60%" height={20} />
            {/* <Skeleton variant="text" width="40%" height={20} /> */}
          </Grid>
        ))}
      </Grid>
      
      <Grid container spacing={3}  style={{marginBottom:'15px'}}>
        {[...Array(3)].map((_, index) => (
          <Grid item xs={12} sm={6} md={4} lg={4} key={index}>
            <Skeleton variant="rectangular" width="100%" height={"250px"} />
            <Skeleton variant="text" width="80%" height={20} />
            <Skeleton variant="text" width="60%" height={20} />
            {/* <Skeleton variant="text" width="40%" height={20} /> */}
          </Grid>
        ))}
      </Grid>
      <Grid container spacing={3}  style={{marginBottom:'15px'}}>
        {[...Array(3)].map((_, index) => (
          <Grid item xs={12} sm={6} md={4} lg={4}  key={index}>
            <Skeleton variant="rectangular" width="100%" height={"250px"} />
            <Skeleton variant="text" width="80%" height={20} />
            <Skeleton variant="text" width="60%" height={20} />
            {/* <Skeleton variant="text" width="40%" height={20} /> */}
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default ViemoreProdSkeleton;
