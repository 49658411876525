import { useState, useEffect, useCallback } from "react";

const getCache = (key) => {
  if (key) {
    const cachedData = localStorage.getItem(key);
    return cachedData ? JSON.parse(cachedData) : null;
  }
  return null;
};

const setCache = (key, data) => {
  if (key) localStorage.setItem(key, JSON.stringify(data));
};

const useFetch = (url, options = {}, dependencies = [], cacheKey = null) => {
  const [data, setData] = useState(() => getCache(cacheKey));
  const [loading, setLoading] = useState(!data);
  const [error, setError] = useState(null);

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await fetch(url, { ...options });
      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
      const json = await response.json();
      setData(json.results);
      setCache(cacheKey, json.results);
    } catch (error) {
      if (error.name !== "AbortError") {
        setError(error); // Only set error state if it's not an abort error
      } else if(error.name === "AbortError"){
        console.log("cancelled")
      }
    } finally {
      setLoading(false);
    }
  }, [url, options, cacheKey, ...dependencies]);

  useEffect(() => {
    if (data) return; // Skip fetch if data is already cached

    fetchData();
    // console.log(data)
  }, [fetchData, data, ...dependencies]);

  return { data, loading, error };
};

export default useFetch;
