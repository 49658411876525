import React from "react";
import Skeleton from "@mui/material/Skeleton";
import Grid from "@mui/material/Grid";

const RecommendedProdsSkeleton = ({ height, length, lg = "3", xl }) => {
  return (
    <>
      {" "}
      {[...Array(3)].map((_, index) => (
        <Grid container spacing={3} key={index} className="mb-4">
          <Grid item>
            <Skeleton variant="rectangular" width={150} height={90} />
            <Skeleton variant="text" width="80%" height={20} />
            <Skeleton variant="text" width="60%" height={20} />
            {/* <Skeleton variant="text" width="40%" height={20} /> */}
          </Grid>
        </Grid>
      ))}
    </>
  );
};

export default RecommendedProdsSkeleton;
