import React from "react";
import Skeleton from "@mui/material/Skeleton";
import Grid from "@mui/material/Grid";

const TrendingProdSkeleton = () => {
  return (
    <>
      {[...Array(5)].map((_, index) => (
        <Grid item xs={12} sm={6} md={4} lg={4} key={index} >
          <div className="trending-product-item mb-30">
            <div className="thumb">
              <Skeleton variant="rectangular" width={60} height={60} />
            </div>

            <div style={{ display: "flex", flexDirection: "column" }}>
              <Skeleton variant="text" width="12rem" height={20} />
              <Skeleton variant="text" width="6rem" height={20} />
            </div>
          </div>
        </Grid>
      ))}
    </>
  );
};

export default TrendingProdSkeleton;

