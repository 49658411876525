import React from 'react';
import {NavLink } from "react-router-dom";
import discount_shape from "../assets/img/images/discount_shape.png";

function Livemarket() {
  return (
    <div>
         <div className="header-action d-none d-md-block">
                      <ul>
                        <li className="header-btn">
                          <NavLink to="/joinlivemarkets">
                           Join Live Market{" "}
                            <img src={discount_shape} alt="discount_shape" />
                          </NavLink>
                        </li>
                      </ul>
                    </div>
    </div>
  )
}

export default Livemarket