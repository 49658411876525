import React from "react";
import { NavLink } from "react-router-dom";
import { useProductData } from "./ProductsContextApi";

function Leftmenuproducts(props) {

  const allBrands = useProductData().brands;
  const specBrand = allBrands?.filter((brand) => brand.subcategorycode === props.subcatcode);

  return specBrand
    ? specBrand.map((post, index) => {
        return (
          <ul key={index}>
            <li>
              <NavLink to={`/products/${post.code}`}>{post.name}</NavLink>
            </li>
          </ul>
        );
      })
    : "";
}

export default Leftmenuproducts;
