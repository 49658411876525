import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { API_BASE_URL } from "../../config/connect";

const SeasonalUseEquipment = ({ onSeasonalUseEquipmentSelected }) => {
  const [selectedSeasonalUseEquipment, setSelectedSeasonalUseEquipment] =
    useState(null);
  const [variations, setVariations] = useState([]);
  const { prodcode } = useParams();

  const getAllProductVariations = async () => {
    try {
      const response = await fetch(
        `https://ghmarkets.net/test/ghmarketconnect/?ghw=productservices&svg=fashionvariations&prcode=${prodcode}`
      );
      const json = await response.json();
      const firstResult = json["results"][0];
      if (firstResult && firstResult.seasonaluseequipment) {
        const seasonalUseEquipmentObject = firstResult.seasonaluseequipment;
        if (seasonalUseEquipmentObject.trim() !== "") {
          setVariations(seasonalUseEquipmentObject.split(","));
        }
      }
    } catch (error) {
      // Handle error here
    }
  };

  useEffect(() => {
    getAllProductVariations();
  }, [prodcode]);

  const handleSeasonalUseEquipmentClick = (seasonaluseequipment) => {
    setSelectedSeasonalUseEquipment(seasonaluseequipment);
    onSeasonalUseEquipmentSelected(seasonaluseequipment);
  };

  return variations.length > 0 ? (
    <div
      className="sizes-container"
      style={{ display: "flex", flexDirection: "row" }}
    >
      <span
        style={{
          fontSize: "",
          fontWeight: "bold",
          color: "black",
          marginTop: "12px",
        }}
      >
        Seasonal Use Equipment:
      </span>
      <div className="sizes">
        {variations.map((variation, i) => (
          <div
            key={i}
            className={`size ${selectedSeasonalUseEquipment === variation ? "selected" : ""}`}
            onClick={() => handleSeasonalUseEquipmentClick(variation)}
          >
            {variation}
          </div>
        ))}
      </div>
    </div>
  ) : (
    ""
  );
};

export default SeasonalUseEquipment;
