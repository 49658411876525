import React from "react";
import Skeleton from "@mui/material/Skeleton";
import Grid from "@mui/material/Grid";

const ProdImagesSkeleton = ({height,length,lg="3",xl}) => {
  return (
    <Grid container spacing={3}>
      {[...Array(5)].map((_, index) => (
        <Grid item  key={index}>
          <Skeleton variant="rectangular" width={90} height={80} />
          {/* <Skeleton variant="text" width="80%" height={20} />
          <Skeleton variant="text" width="60%" height={20} /> */}
          {/* <Skeleton variant="text" width="40%" height={20} /> */}
        </Grid>
      ))}
    </Grid>
  );
};

export default ProdImagesSkeleton;
