import { createSlice } from "@reduxjs/toolkit";

const initialState = [];

const compareProductSlice = createSlice({
  name: "compare",
  initialState,
  reducers: {
    addToCompare: (state, action) => {
      const product_compare = state.find(
        (product) => product.prodcode === action.payload.prodcode
      );

      // console.log("product_compare...");
      // console.log(state);

      if (product_compare) {
      } else {
        state.push({ ...action.payload });
      }

      localStorage.setItem("compare", JSON.stringify(state));
      // console.log(state);
      // console.log(action);
    },

    removeFromCompare: (state, action) => {
      // check if the product exist
      const productToRemove = state.find(
        (product) => product.prodcode === action.payload
      );

      // find index of the product removing
      const index = state.findIndex(
        (product) => product.prodcode === action.payload
      );
      // remove product from array
      state.splice(index, 1);

      localStorage.setItem("compare", JSON.stringify(state));
      // return state.filter((item) => item.prodcode !== action.payload);
    },
  },
});

export const { addToCompare, removeFromCompare } = compareProductSlice.actions;
export default compareProductSlice.reducer;
