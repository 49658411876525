import React from "react";
import Skeleton from "@mui/material/Skeleton";
import Grid from "@mui/material/Grid";

const ProdImageSkeleton = ({height,length,lg="3",xl}) => {
  return (
    <Grid container spacing={3}>
      {/* {[...Array(5)].map((_, index) => ( */}
        <Grid item  >
          <Skeleton variant="rectangular" width={540} height={500} />
          {/* <Skeleton variant="text" width="80%" height={20} />
          <Skeleton variant="text" width="60%" height={20} /> */}
          {/* <Skeleton variant="text" width="40%" height={20} /> */}
        </Grid>
      {/* ))} */}
    </Grid>
  );
};

export default ProdImageSkeleton;
