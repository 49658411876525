import React from "react";
import { NavLink } from "react-router-dom";
import ViemoreProdSkeleton from "./ViemoreProdSkeleton";
import Nodata from "./Nodata";

function Profservicessingle({ slicedPosts, loading, view }) {
  if (loading) {
    return <ViemoreProdSkeleton />;
  } else if (slicedPosts?.length === 0) {
    return <Nodata />;
  } else {
    return (
      <>
        {slicedPosts?.map((post, index) => (
          <div
            key={index}
            className={
              view ? "col-xl-4 col-lg-6 col-md-6 col-sm-6" : "col-12 mb-3"
            }
          >
            <div className={`${view || "row custom"} shop-product-item mb-30`}>
              <div className={`${view || "col-6"} shop-thumb`}>
                <NavLink to={`/shopdetail/${post.code}`}>
                  <img
                    src={post.previewpicture || post.picture}
                    alt="img"
                    width="250px"
                    height="auto"
                  />
                </NavLink>
                {/* <span>New</span> */}
              </div>
              <div className={`${view || "col-6"} shop-content`}>
                <h2 className="title">
                  <NavLink to={`/shopdetail/${post.code}`}>{post.name}</NavLink>
                </h2>
                {/* <ul className="tag">
                  <li>
                    Packaging <NavLink to="">{post.packagetype}</NavLink>
                  </li>
                </ul> */}
                <div className="rating d-flex">
                  <div>
                    {Array.from({ length: 5 }, (_, index) => (
                      <i
                        key={index}
                        className="fa-solid fa-star"
                        style={{
                          color:
                            index < Math.floor(post.ratingaverage)
                              ? "#ffc107"
                              : "#e4e5e9",
                        }}
                      ></i>
                    ))}
                  </div>
                  <div>
                    <h6
                      style={{
                        fontWeight: "normal",
                        fontSize: "12px",
                        marginLeft: "3px",
                        marginTop: "9px",
                      }}
                    >
                      ({post.ratingcount})
                    </h6>
                  </div>
                </div>
                {/* <span>Discounted : 20%</span> */}
                {/* <div className="progress">
                  <div
                    className="progress-bar w-50"
                    role="progressbar"
                    aria-valuenow="50"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div> */}
                <div className="content-bottom">
                  <h4 className="price">
                    {post.price
                      ? `GH₵ ${Number(post.price).toFixed(2).toLocaleString()}`
                      : ""}
                  </h4>
                  <p className={view || "mt-3 text-left ml-0"}>
                  {" "}
                  <NavLink to={`/shopdetail/${post.code}`}>
                    <span>View Details</span>
                  </NavLink>
                </p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </>
    );
  }
}

export default Profservicessingle;
