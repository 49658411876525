import React from 'react';
import { ToastContainer } from 'react-toastify';
import Header from '../components/Header';

const Termcon = () => {
    return (
			<>
				<Header />
				<section className='terms-and-conditions-section py-5'>
					<div className='container-fluid'>
						<div className='row justify-content-center'>
							<div className='col-lg-8'>
								<div className='card shadow p-4'>
									<div className='card-header text-white text-center'>
										<h2 className='mb-0'> Terms and Conditions </h2>{' '}
									</div>{' '}
									<div className='card-body'>
										<div className='mb-4'>
											<h6> 1. Use of the Platform </h6>{' '}
											<p className='lead'>
												Eligibility: You must be at least 18 years old and
												capable of forming a binding contract to use the
												Platform.By using the Platform, you represent and
												warrant that you meet these eligibility requirements.{' '}
											</p>{' '}
											<p className='lead'>
												User Account: To access certain features of the
												Platform, you may be required to create a user
												account.You agree to provide accurate and complete
												information when creating your account and to keep your
												login credentials confidential.{' '}
											</p>{' '}
											<p className='lead'>
												Acceptable Use: You agree to use the Platform only for
												lawful purposes and in accordance with these Terms.You
												may not use the Platform in any manner that could
												damage, disable, overburden, or impair the Platform, or
												interfere with any other party 's use and enjoyment of
												the Platform.{' '}
											</p>{' '}
										</div>{' '}
										<div className='mb-4'>
											<h6> 2. Content and Intellectual Property </h6>{' '}
											<p className='lead'>
												Content Ownership: The Platform may contain content
												owned or licensed by us.This content is protected by
												copyright, trademark, and other laws.You may not modify,
												reproduce, distribute, or create derivative works based
												on this content without our prior written consent.{' '}
											</p>{' '}
											<p className='lead'>
												User - Generated Content: By submitting content to the
												Platform, you grant us a non - exclusive, royalty -
												free, worldwide, perpetual, and irrevocable license to
												use, reproduce, modify, adapt, publish, translate,
												distribute, and display such content in connection with
												the Platform.{' '}
											</p>{' '}
										</div>{' '}
										<div className='mb-4'>
											<h6> 3. Payments and Fees </h6>{' '}
											<p className='lead'>
												Payment Processing: Certain features of the Platform may
												require payment of fees.By using these features, you
												agree to pay all applicable fees and charges.Payments
												are processed through third - party payment processors,
												and you agree to comply with their terms and conditions.{' '}
											</p>{' '}
											<p className='lead'>
												Refunds: Fees paid for the use of the Platform are non -
												refundable unless otherwise stated in writing by us.{' '}
											</p>{' '}
										</div>{' '}
										<div className='mb-4'>
											<h6> 4. Termination </h6>{' '}
											<p className='lead'>
												Termination by Us: We reserve the right to suspend or
												terminate your access to the Platform at any time and
												for any reason, without notice or liability.{' '}
											</p>{' '}
											<p className='lead'>
												Termination by You: You may terminate your account and
												stop using the Platform at any time by following the
												provided instructions.{' '}
											</p>{' '}
										</div>{' '}
										<div className='mb-4'>
											<h6> 5. Privacy Policy </h6>{' '}
											<p className='lead'>
												Privacy: Your use of the Platform is subject to our
												Privacy Policy, which governs our collection, use, and
												disclosure of your personal information.{' '}
											</p>{' '}
										</div>{' '}
										<div className='mb-4'>
											<h6> 6. Limitation of Liability </h6>{' '}
											<p className='lead'>
												Disclaimer: The Platform is provided on an "as-is" and
												"as-available" basis, without any warranties of any
												kind, either express or implied.We disclaim all
												warranties, including but not limited to the implied
												warranties of merchantability, fitness for a particular
												purpose, and non - infringement.{' '}
											</p>{' '}
											<p className='lead'>
												Limitation of Liability: To the fullest extent permitted
												by law, we will not be liable for any indirect,
												incidental, special, consequential, or punitive damages
												arising out of or in connection with your use of the
												Platform.{' '}
											</p>{' '}
										</div>{' '}
									</div>{' '}
								</div>{' '}
							</div>{' '}
						</div>{' '}
					</div>{' '}
				</section>{' '}
				<ToastContainer />
			</>
		);
};

export default Termcon;