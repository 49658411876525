import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import { API_BASE_URL } from "../config/connect";
import Sizes from "./variations/Sizes";
import ProductColor from "./variations/ProductColor";
import Material from "./variations/Material";
import Style from "./variations/Style";
import Theme from "./variations/Theme";
import Thickness from "./variations/Thickness";
import Elasticity from "./variations/Elasticity";
import Fit from "./variations/Fit";
import Neckline from "./variations/Neckline";
import Sleeve from "./variations/Sleeve";
import Pattern from "./variations/Pattern";
import Waistline from "./variations/Waistline";
import Batteries from "./variations/Batteries";
import Uhd from "./variations/Uhd";
import Expirydate from "./variations/Expirydate";
import Finish from "./variations/Finish";
import Packaging from "./variations/Packaging";
import Foundation from "./variations/Foundation";
import Fragrance from "./variations/Fragrance";
import Productiondate from "./variations/Productiondate";
import Accessories from "./variations/Accessories";
import ActivityType from "./variations/ActivityType";
import Amplification from "./variations/Amplification";
import Audio from "./variations/Audio";
import AutomationLevel from "./variations/AutomationLevel";
import BallType from "./variations/BallType";
import BandMaterial from "./variations/BandMaterial";
import BatteryLife from "./variations/BatteryLife";
import BodyType from "./variations/BodyType";
import CameraResolution from "./variations/CameraResolution";
import Capacity from "./variations/Capacity";
import CaseShape from "./variations/CaseShape";
import Certification from "./variations/Certification";
import ClaspType from "./variations/ClaspType";
import ComfortFeatures from "./variations/ComfortFeatures";
import Connectivity from "./variations/Connectivity";
import ControlMethod from "./variations/ControlMethod";
import DisplayType from "./variations/DisplayType";
import DosageForm from "./variations/DosageForm";
import DriveType from "./variations/DriveType";
import DrumConfiguration from "./variations/DrumConfiguration";
import DrumHeads from "./variations/DrumHeads";
import Durability from "./variations/Durability";
import EaseOfUseCampingEquipment from "./variations/EaseOfUseCampingEquipment";
import EaseOfUseExerciseEquipment from "./variations/EaseOfUseExerciseEquipment";
import Effects from "./variations/Effects";
import EnergyEfficiency from "./variations/EnergyEfficiency";
import EngineSize from "./variations/EngineSize";
import Feature from "./variations/Feature";
import Freshness from "./variations/Freshness";
import Fuel from "./variations/Fuel";
import FurnitureType from "./variations/FurnitureType";
import Gemstone from "./variations/Gemstone";
import Gender from "./variations/Gender";
import GraphicsCard from "./variations/GraphicsCard";
import Inflation from "./variations/Inflation";
import Keys from "./variations/Keys";
import LevelSize from "./variations/LevelSize";
import LightingType from "./variations/LevelSize";
import MedicalCondition from "./variations/MedicalCondition";
import MicPlacement from "./variations/MicPlacement";
import Modifications from "./variations/Modifications";
import MountingType from "./variations/MountingType";
import Mouthpiece from "./variations/Mouthpiece";
import Movement from "./variations/Movement";
import OperatingSystem from "./variations/OperatingSystem";
import Pedals from "./variations/Pedals";
import PerformanceFeatures from "./variations/PerformanceFeatures";
import PesticideUse from "./variations/PesticideUse";
import Ports from "./variations/Ports";
import PowerSource from "./variations/PowerSource";
import Processing from "./variations/Processing";
import ProcessorType from "./variations/ProcessorType";
import ProduceClass from "./variations/ProduceClass";
import ProductType from "./variations/ProductType";
import Purpose from "./variations/Purpose";
import RamSize from "./variations/RamSize";
import RefreshRate from "./variations/RefreshRate";
import Ripeness from "./variations/Ripeness";
import SafetyFeatures from "./variations/SafetyFeatures";
import ScreenSize from "./variations/ScreenSize";
import SeasonalUseEquipment from "./variations/SeasonalUseEquipment";
import SeatingCapacity from "./variations/SeatingCapacity";
import SensorType from "./variations/SensorType";
import SpecialFeatures from "./variations/SpecialFeatures";
import StorageCapacity from "./variations/StorageCapacity";
import StorageRequirements from "./variations/StorageRequirements";
import Strings from "./variations/Strings";
import TargetedAudience from "./variations/TargetedAudience";
import Technology from "./variations/Technology";
import TechnologyFeatures from "./variations/TechnologyFeatures";
import Touchscreen from "./variations/Touchscreen";
import TrackingFeatures from "./variations/TrackingFeatures";
import Transmission from "./variations/Transmission";
import Tuning from "./variations/Tuning";
import Usage from "./variations/Usage";
import UsageEnvironment from "./variations/UsageEnvironment";
import UsageSurface from "./variations/UsageSurface";
import Warranty from "./variations/Warranty";
import WaterResistance from "./variations/WaterResistance";
import Weight from "./variations/Weight";
import WirelessConnectivity from "./variations/WirelessConnectivity";
import Wood from "./variations/Wood";
import Skintype from "./variations/Skintype";
import Ingredients from "./variations/Ingredients";

const ProductVariations = ({ onSelectVariations }) => {
  const { prodcode } = useParams();
  const [selectedVariations, setSelectedVariations] = useState({
    selectedSize: null,
    selectedColor: null,
    selectedMaterial: null,
    selectedStyle: null,
    selectedTheme: null,
    selectedThickness: null,
    selectedElasticity: null,
    selectedFit: null,
    selectedNeckline: null,
    selectedSleeve: null,
    selectedPattern: null,
    selectedWaistline: null,
    selectedBatteries: null,
    selectedUhd: null,
    selectedIngredients: null,
    selectedSkintype: null,
    selectedFinish: null,
    selectedPackaging: null,
    selectedFragrance: null,
    selectedFoundation: null,
    selectedProductiondate: null,
    selectedExpirydate: null,
    selectedAccessories: null,
    selectedActivityType: null,
    selectedAmplification: null,
    selectedAudio: null,
    selectedAutomationLevel: null,
    selectedBallType: null,
    selectedBandMaterial: null,
    selectedBatteryLife: null,
    selectedBodyType: null,
    selectedCameraResolution: null,
    selectedCapacity: null,
    selectedCaseShape: null,
    selectedCertification: null,
    selectedClaspType: null,
    selectedComfortFeatures: null,
    selectedConnectivity: null,
    selectedControlMethod: null,
    selectedDisplayType: null,
    selectedDosageForm: null,
    selectedDriveType: null,
    selectedDrumConfiguration: null,
    selectedDrumHeads: null,
    selectedDurability: null,
    selectedEaseOfUseCampingEquipment: null,
    selectedEaseOfUseExerciseEquipment: null,
    selectedEffects: null,
    selectedEnergyEfficiency: null,
    selectedEngineSize: null,
    selectedFeature: null,
    selectedFreshness: null,
    selectedFuel: null,
    selectedFurnitureType: null,
    selectedGemstone: null,
    selectedGender: null,
    selectedGraphicsCard: null,
    selectedInflation: null,
    selectedKeys: null,
    selectedLevelSize: null,
    selectedLightingType: null,
    selectedMedicalCondition: null,
    selectedMicPlacement: null,
    selectedModifications: null,
    selectedMountingType: null,
    selectedMouthpiece: null,
    selectedMovement: null,
    selectedOperatingSystem: null,
    selectedPedals: null,
    selectedPerformanceFeatures: null,
    selectedPesticideUse: null,
    selectedPorts: null,
    selectedPowerSource: null,
    selectedProcessing: null,
    selectedProcessorType: null,
    selectedProduceClass: null,
    selectedProductType: null,
    selectedPurpose: null,
    selectedRamSize: null,
    selectedRefreshRate: null,
    selectedRipeness: null,
    selectedSafetyFeatures: null,
    selectedScreenSize: null,
    selectedSeasonalUseEquipment: null,
    selectedSeatingCapacity: null,
    selectedSensorType: null,
    selectedSpecialFeatures: null,
    selectedStorageCapacity: null,
    selectedStorageRequirements: null,
    selectedStrings: null,
    selectedTargetedAudience: null,
    selectedTechnology: null,
    selectedTechnologyFeatures: null,
    selectedTouchscreen: null,
    selectedTrackingFeatures: null,
    selectedTransmission: null,
    selectedTuning: null,
    selectedUsage: null,
    selectedUsageEnvironment: null,
    selectedUsageSurface: null,
    selectedWarranty: null,
    selectedWaterResistance: null,
    selectedWeight: null,
    selectedWirelessConnectivity: null,
    selectedWood: null,
  });

  const [sku, setSKU] = useState("");
  const [sellerregion, setRegion] = useState("");
  const [model, setModel] = useState("");

  useEffect(() => {
    const getAllProducts = async () => {
      try {
        const response = await fetch(
          `https://ghmarkets.net/test/ghmarketconnect/?ghw=productservices&svg=details&prcode=${prodcode}`
        );
        const json = await response.json();
        const product = json.results[0];

        // console.log(product);
        if (product) {
          setSKU(product.sku);
          setRegion(product.region);
          setModel(product.modelname);
        }
      } catch (error) {
        console.error("Error fetching product details:", error);
      }
    };

    getAllProducts();
  }, [prodcode]);

  const handleVariationsSelected = (variations) => {
    setSelectedVariations(variations);
    onSelectVariations(variations);
  };

  return (
    <>
      <ul>
        <li className="sd-category">
          <span className="title">Model:</span>
          <NavLink to="">{model}</NavLink>
        </li>
        <li className="sd-category">
          <Sizes
            onSizeSelected={(size) =>
              handleVariationsSelected({
                ...selectedVariations,
                selectedSize: size,
              })
            }
          />
        </li>
        <li className="sd-category">
          <ProductColor
            onColorSelected={(color) =>
              handleVariationsSelected({
                ...selectedVariations,
                selectedColor: color,
              })
            }
          />
        </li>
        <li className="sd-category">
          <Material
            onMaterialSelected={(material) =>
              handleVariationsSelected({
                ...selectedVariations,
                selectedMaterial: material,
              })
            }
          />
        </li>
        <li className="sd-category">
          <Style
            onStyleSelected={(style) =>
              handleVariationsSelected({
                ...selectedVariations,
                selectedStyle: style,
              })
            }
          />
        </li>
        <li className="sd-category">
          <Theme
            onThemeSelected={(theme) =>
              handleVariationsSelected({
                ...selectedVariations,
                selectedTheme: theme,
              })
            }
          />
        </li>
        <li className="sd-category">
          <Thickness
            onThicknessSelected={(thickness) =>
              handleVariationsSelected({
                ...selectedVariations,
                selectedThickness: thickness,
              })
            }
          />
        </li>
        <li className="sd-category">
          <Elasticity
            onElasticitySelected={(elasticity) =>
              handleVariationsSelected({
                ...selectedVariations,
                selectedElasticity: elasticity,
              })
            }
          />
        </li>
        <li className="sd-category">
          <Fit
            onFitSelected={(fit) =>
              handleVariationsSelected({
                ...selectedVariations,
                selectedFit: fit,
              })
            }
          />
        </li>
        <li className="sd-category">
          <Neckline
            onNecklineSelected={(neckline) =>
              handleVariationsSelected({
                ...selectedVariations,
                selectedNeckline: neckline,
              })
            }
          />
        </li>
        <li className="sd-category">
          <Sleeve
            onSleeveSelected={(sleeve) =>
              handleVariationsSelected({
                ...selectedVariations,
                selectedSleeve: sleeve,
              })
            }
          />
        </li>
        <li className="sd-category">
          <Pattern
            onPatternSelected={(pattern) =>
              handleVariationsSelected({
                ...selectedVariations,
                selectedPattern: pattern,
              })
            }
          />
        </li>
        <li className="sd-category">
          <Waistline
            onWaistlineSelected={(waistline) =>
              handleVariationsSelected({
                ...selectedVariations,
                selectedWaistline: waistline,
              })
            }
          />
        </li>
        <li className="sd-category">
          <Batteries
            onBatteriesSelected={(batteries) =>
              handleVariationsSelected({
                ...selectedVariations,
                selectedBatteries: batteries,
              })
            }
          />
        </li>
        <li className="sd-category">
          <Uhd
            onUhdSelected={(uhd) =>
              handleVariationsSelected({
                ...selectedVariations,
                selectedUhd: uhd,
              })
            }
          />
        </li>
        <li className="sd-category">
          <Ingredients
            onIngredientSelected={(ingredients) =>
              handleVariationsSelected({
                ...selectedVariations,
                selectedIngredients: ingredients,
              })
            }
          />
        </li>
        <li className="sd-category">
          <Skintype
            onSkintypeSelected={(skintype) =>
              handleVariationsSelected({
                ...selectedVariations,
                selectedSkintype: skintype,
              })
            }
          />
        </li>
        <li className="sd-category">
          <Finish
            onFinishSelected={(finish) =>
              handleVariationsSelected({
                ...selectedVariations,
                selectedFinish: finish,
              })
            }
          />
        </li>
        <li className="sd-category">
          <Packaging
            onPackagingSelected={(packaging) =>
              handleVariationsSelected({
                ...selectedVariations,
                selectedPackaging: packaging,
              })
            }
          />
        </li>
        <li className="sd-category">
          <Fragrance
            onFragranceSelected={(fragrance) =>
              handleVariationsSelected({
                ...selectedVariations,
                selectedFragrance: fragrance,
              })
            }
          />
        </li>
        <li className="sd-category">
          <Foundation
            onFoundationSelected={(foundation) =>
              handleVariationsSelected({
                ...selectedVariations,
                selectedFoundation: foundation,
              })
            }
          />
        </li>
        <li className="sd-category">
          <Productiondate
            onProductiondateSelected={(productiondate) =>
              handleVariationsSelected({
                ...selectedVariations,
                selectedProductiondate: productiondate,
              })
            }
          />
        </li>
        <li className="sd-category">
          <Expirydate
            onExpirydateSelected={(expirydate) =>
              handleVariationsSelected({
                ...selectedVariations,
                selectedExpirydate: expirydate,
              })
            }
          />
        </li>
        <li className="sd-category">
          <Accessories
            onAccessoriesSelected={(accessories) =>
              handleVariationsSelected({
                ...selectedVariations,
                selectedAccessories: accessories,
              })
            }
          />
        </li>
        <li className="sd-category">
          <ActivityType
            onActivityTypeSelected={(activitytype) =>
              handleVariationsSelected({
                ...selectedVariations,
                selectedActivityType: activitytype,
              })
            }
          />
        </li>
        <li className="sd-category">
          <Amplification
            onAmplificationSelected={(amplification) =>
              handleVariationsSelected({
                ...selectedVariations,
                selectedAmplification: amplification,
              })
            }
          />
        </li>
        <li className="sd-category">
          <Audio
            onAudioSelected={(audio) =>
              handleVariationsSelected({
                ...selectedVariations,
                selectedAudio: audio,
              })
            }
          />
        </li>
        <li className="sd-category">
          <AutomationLevel
            onAutomationLevelSelected={(automationlevel) =>
              handleVariationsSelected({
                ...selectedVariations,
                selectedAutomationLevel: automationlevel,
              })
            }
          />
        </li>
        <li className="sd-category">
          <BallType
            onBallTypeSelected={(balltype) =>
              handleVariationsSelected({
                ...selectedVariations,
                selectedBallType: balltype,
              })
            }
          />
        </li>
        <li className="sd-category">
          <BandMaterial
            onBandMaterialSelected={(bandmaterial) =>
              handleVariationsSelected({
                ...selectedVariations,
                selectedBandMaterial: bandmaterial,
              })
            }
          />
        </li>
        <li className="sd-category">
          <BatteryLife
            onBatteryLifeSelected={(batterylife) =>
              handleVariationsSelected({
                ...selectedVariations,
                selectedBatteryLife: batterylife,
              })
            }
          />
        </li>
        <li className="sd-category">
          <BodyType
            onBodyTypeSelected={(bodytype) =>
              handleVariationsSelected({
                ...selectedVariations,
                selectedBodyType: bodytype,
              })
            }
          />
        </li>
        <li className="sd-category">
          <CameraResolution
            onCameraResolutionSelected={(cameraresolution) =>
              handleVariationsSelected({
                ...selectedVariations,
                selectedCameraResolution: cameraresolution,
              })
            }
          />
        </li>
        <li className="sd-category">
          <Capacity
            onCapacitySelected={(capacity) =>
              handleVariationsSelected({
                ...selectedVariations,
                selectedCapacity: capacity,
              })
            }
          />
        </li>
        <li className="sd-category">
          <CaseShape
            onCaseShapeSelected={(caseshape) =>
              handleVariationsSelected({
                ...selectedVariations,
                selectedCaseShape: caseshape,
              })
            }
          />
        </li>
        <li className="sd-category">
          <Certification
            onCertificationSelected={(certification) =>
              handleVariationsSelected({
                ...selectedVariations,
                selectedCertification: certification,
              })
            }
          />
        </li>
        <li className="sd-category">
          <ClaspType
            onClaspTypeSelected={(clasptype) =>
              handleVariationsSelected({
                ...selectedVariations,
                selectedClaspType: clasptype,
              })
            }
          />
        </li>
        <li className="sd-category">
          <ComfortFeatures
            onComfortFeaturesSelected={(comfortfeatures) =>
              handleVariationsSelected({
                ...selectedVariations,
                selectedComfortFeatures: comfortfeatures,
              })
            }
          />
        </li>
        <li className="sd-category">
          <Connectivity
            onConnectivitySelected={(connectivity) =>
              handleVariationsSelected({
                ...selectedVariations,
                selectedConnectivity: connectivity,
              })
            }
          />
        </li>
        <li className="sd-category">
          <ControlMethod
            onControlMethodSelected={(controlmethod) =>
              handleVariationsSelected({
                ...selectedVariations,
                selectedControlMethod: controlmethod,
              })
            }
          />
        </li>
        <li className="sd-category">
          <DisplayType
            onDisplayTypeSelected={(displaytype) =>
              handleVariationsSelected({
                ...selectedVariations,
                selectedDisplayType: displaytype,
              })
            }
          />
        </li>
        <li className="sd-category">
          <DosageForm
            onDosageFormSelected={(dosageform) =>
              handleVariationsSelected({
                ...selectedVariations,
                selectedDosageForm: dosageform,
              })
            }
          />
        </li>
        <li className="sd-category">
          <DriveType
            onDriveTypeSelected={(drivetype) =>
              handleVariationsSelected({
                ...selectedVariations,
                selectedDriveType: drivetype,
              })
            }
          />
        </li>
        <li className="sd-category">
          <DrumConfiguration
            onDrumConfigurationSelected={(drumconfiguration) =>
              handleVariationsSelected({
                ...selectedVariations,
                selectedDrumConfiguration: drumconfiguration,
              })
            }
          />
        </li>
        <li className="sd-category">
          <DrumHeads
            onDrumHeadSelected={(drumheads) =>
              handleVariationsSelected({
                ...selectedVariations,
                selectedDrumHeads: drumheads,
              })
            }
          />
        </li>
        <li className="sd-category">
          <Durability
            onDurabilitySelected={(durability) =>
              handleVariationsSelected({
                ...selectedVariations,
                selectedDurability: durability,
              })
            }
          />
        </li>
        <li className="sd-category">
          <EaseOfUseCampingEquipment
            onEaseOfUseCampingEquipmentSelected={(easeofusecampingequipment) =>
              handleVariationsSelected({
                ...selectedVariations,
                selectedEaseOfUseCampingEquipment: easeofusecampingequipment,
              })
            }
          />
        </li>
        <li className="sd-category">
          <EaseOfUseExerciseEquipment
            onEaseOfUseExerciseEquipmentSelected={(
              easeofuseexerciseequipment
            ) =>
              handleVariationsSelected({
                ...selectedVariations,
                selectedEaseOfUseExerciseEquipment: easeofuseexerciseequipment,
              })
            }
          />
        </li>
        <li className="sd-category">
          <Effects
            onEffectSelected={(effects) =>
              handleVariationsSelected({
                ...selectedVariations,
                selectedEffects: effects,
              })
            }
          />
        </li>
        <li className="sd-category">
          <EnergyEfficiency
            onEnergyEfficiencySelected={(energyefficiency) =>
              handleVariationsSelected({
                ...selectedVariations,
                selectedEnergyEfficiency: energyefficiency,
              })
            }
          />
        </li>
        <li className="sd-category">
          <EngineSize
            onEngineSizeSelected={(enginesize) =>
              handleVariationsSelected({
                ...selectedVariations,
                selectedEngineSize: enginesize,
              })
            }
          />
        </li>
        <li className="sd-category">
          <Feature
            onFeatureSelected={(feature) =>
              handleVariationsSelected({
                ...selectedVariations,
                selectedFeature: feature,
              })
            }
          />
        </li>
        <li className="sd-category">
          <Freshness
            onFreshnessSelected={(freshness) =>
              handleVariationsSelected({
                ...selectedVariations,
                selectedFreshness: freshness,
              })
            }
          />
        </li>
        <li className="sd-category">
          <Fuel
            onFuelSelected={(fuel) =>
              handleVariationsSelected({
                ...selectedVariations,
                selectedFuel: fuel,
              })
            }
          />
        </li>
        <li className="sd-category">
          <FurnitureType
            onFurnitureTypeSelected={(furnituretype) =>
              handleVariationsSelected({
                ...selectedVariations,
                selectedFurnitureType: furnituretype,
              })
            }
          />
        </li>
        <li className="sd-category">
          <Gemstone
            onGemstoneSelected={(gemstone) =>
              handleVariationsSelected({
                ...selectedVariations,
                selectedGemstone: gemstone,
              })
            }
          />
        </li>
        <li className="sd-category">
          <Gender
            onGenderSelected={(gender) =>
              handleVariationsSelected({
                ...selectedVariations,
                selectedGender: gender,
              })
            }
          />
        </li>
        <li className="sd-category">
          <GraphicsCard
            onGraphicsCardSelected={(graphicscard) =>
              handleVariationsSelected({
                ...selectedVariations,
                selectedGraphicsCard: graphicscard,
              })
            }
          />
        </li>
        <li className="sd-category">
          <Inflation
            onInflationSelected={(inflation) =>
              handleVariationsSelected({
                ...selectedVariations,
                selectedInflation: inflation,
              })
            }
          />
        </li>
        <li className="sd-category">
          <Keys
            onKeySelected={(keys) =>
              handleVariationsSelected({
                ...selectedVariations,
                selectedKeys: keys,
              })
            }
          />
        </li>
        <li className="sd-category">
          <LevelSize
            onLevelSizeSelected={(levelsize) =>
              handleVariationsSelected({
                ...selectedVariations,
                selectedLevelSize: levelsize,
              })
            }
          />
        </li>
        <li className="sd-category">
          <LightingType
            onLightingTypeSelected={(lightingtype) =>
              handleVariationsSelected({
                ...selectedVariations,
                selectedLightingType: lightingtype,
              })
            }
          />
        </li>
        <li className="sd-category">
          <MedicalCondition
            onMedicalConditionSelected={(medicalcondition) =>
              handleVariationsSelected({
                ...selectedVariations,
                selectedMedicalCondition: medicalcondition,
              })
            }
          />
        </li>
        <li className="sd-category">
          <MicPlacement
            onMicPlacementSelected={(micplacement) =>
              handleVariationsSelected({
                ...selectedVariations,
                selectedMicPlacement: micplacement,
              })
            }
          />
        </li>
        <li className="sd-category">
          <Modifications
            onModificationSelected={(modifications) =>
              handleVariationsSelected({
                ...selectedVariations,
                selectedModifications: modifications,
              })
            }
          />
        </li>
        <li className="sd-category">
          <MountingType
            onMountingTypeSelected={(mountingtype) =>
              handleVariationsSelected({
                ...selectedVariations,
                selectedMountingType: mountingtype,
              })
            }
          />
        </li>
        <li className="sd-category">
          <Mouthpiece
            onMouthpieceSelected={(mouthpiece) =>
              handleVariationsSelected({
                ...selectedVariations,
                selectedMouthpiece: mouthpiece,
              })
            }
          />
        </li>
        <li className="sd-category">
          <Movement
            onMovementSelected={(movement) =>
              handleVariationsSelected({
                ...selectedVariations,
                selectedMovement: movement,
              })
            }
          />
        </li>
        <li className="sd-category">
          <OperatingSystem
            onOperatingSystemSelected={(operatingsystem) =>
              handleVariationsSelected({
                ...selectedVariations,
                selectedOperatingSystem: operatingsystem,
              })
            }
          />
        </li>
        <li className="sd-category">
          <Pedals
            onPedalSelected={(pedals) =>
              handleVariationsSelected({
                ...selectedVariations,
                selectedPedals: pedals,
              })
            }
          />
        </li>
        <li className="sd-category">
          <PerformanceFeatures
            onPerformanceFeaturesSelected={(performancefeatures) =>
              handleVariationsSelected({
                ...selectedVariations,
                selectedPerformanceFeatures: performancefeatures,
              })
            }
          />
        </li>
        <li className="sd-category">
          <PesticideUse
            onPesticideUseSelected={(pesticideuse) =>
              handleVariationsSelected({
                ...selectedVariations,
                selectedPesticideUse: pesticideuse,
              })
            }
          />
        </li>
        <li className="sd-category">
          <Ports
            onPortSelected={(ports) =>
              handleVariationsSelected({
                ...selectedVariations,
                selectedPorts: ports,
              })
            }
          />
        </li>
        <li className="sd-category">
          <PowerSource
            onPowerSourceSelected={(powersource) =>
              handleVariationsSelected({
                ...selectedVariations,
                selectedPowerSource: powersource,
              })
            }
          />
        </li>
        <li className="sd-category">
          <Processing
            onProcessingSelected={(processing) =>
              handleVariationsSelected({
                ...selectedVariations,
                selectedProcessing: processing,
              })
            }
          />
        </li>
        <li className="sd-category">
          <ProcessorType
            onProcessorTypeSelected={(processortype) =>
              handleVariationsSelected({
                ...selectedVariations,
                selectedProcessorType: processortype,
              })
            }
          />
        </li>
        <li className="sd-category">
          <ProduceClass
            onProduceClassSelected={(produceclass) =>
              handleVariationsSelected({
                ...selectedVariations,
                selectedProduceClass: produceclass,
              })
            }
          />
        </li>
        <li className="sd-category">
          <ProductType
            onProductTypeSelected={(producttype) =>
              handleVariationsSelected({
                ...selectedVariations,
                selectedProductType: producttype,
              })
            }
          />
        </li>
        <li className="sd-category">
          <Purpose
            onPurposeSelected={(purpose) =>
              handleVariationsSelected({
                ...selectedVariations,
                selectedPurpose: purpose,
              })
            }
          />
        </li>
        <li className="sd-category">
          <RamSize
            onRamSizeSelected={(ramsize) =>
              handleVariationsSelected({
                ...selectedVariations,
                selectedRamSize: ramsize,
              })
            }
          />
        </li>
        <li className="sd-category">
          <RefreshRate
            onRefreshRateSelected={(refreshrate) =>
              handleVariationsSelected({
                ...selectedVariations,
                selectedRefreshRate: refreshrate,
              })
            }
          />
        </li>
        <li className="sd-category">
          <Ripeness
            onRipenessSelected={(ripeness) =>
              handleVariationsSelected({
                ...selectedVariations,
                selectedRipeness: ripeness,
              })
            }
          />
        </li>
        <li className="sd-category">
          <SafetyFeatures
            onSafetyFeaturesSelected={(safetyfeatures) =>
              handleVariationsSelected({
                ...selectedVariations,
                selectedSafetyFeatures: safetyfeatures,
              })
            }
          />
        </li>
        <li className="sd-category">
          <SeasonalUseEquipment
            onSeasonalUseEquipmentSelected={(seasonaluseequipment) =>
              handleVariationsSelected({
                ...selectedVariations,
                selectedSeasonalUseEquipment: seasonaluseequipment,
              })
            }
          />
        </li>
        <li className="sd-category">
          <SensorType
            onSensorTypeSelected={(sensortype) =>
              handleVariationsSelected({
                ...selectedVariations,
                selectedSensorType: sensortype,
              })
            }
          />
        </li>
        <li className="sd-category">
          <ScreenSize
            onScreenSizeSelected={(screensize) =>
              handleVariationsSelected({
                ...selectedVariations,
                selectedScreenSize: screensize,
              })
            }
          />
        </li>
        <li className="sd-category">
          <SeatingCapacity
            onSeatingCapacitySelected={(seatingcapacity) =>
              handleVariationsSelected({
                ...selectedVariations,
                selectedSeatingCapacity: seatingcapacity,
              })
            }
          />
        </li>
        <li className="sd-category">
          <SpecialFeatures
            onSpecialFeaturesSelected={(specialfeatures) =>
              handleVariationsSelected({
                ...selectedVariations,
                selectedSpecialFeatures: specialfeatures,
              })
            }
          />
        </li>
        <li className="sd-category">
          <StorageCapacity
            onStorageCapacitySelected={(storagecapacity) =>
              handleVariationsSelected({
                ...selectedVariations,
                selectedStorageCapacity: storagecapacity,
              })
            }
          />
        </li>
        <li className="sd-category">
          <StorageRequirements
            onStorageRequirementsSelected={(storagerequirements) =>
              handleVariationsSelected({
                ...selectedVariations,
                selectedStorageRequirements: storagerequirements,
              })
            }
          />
        </li>
        <li className="sd-category">
          <Strings
            onStringsSelected={(strings) =>
              handleVariationsSelected({
                ...selectedVariations,
                selectedStrings: strings,
              })
            }
          />
        </li>
        <li className="sd-category">
          <TargetedAudience
            onTargetedAudienceSelected={(targetedaudience) =>
              handleVariationsSelected({
                ...selectedVariations,
                selectedTargetedAudience: targetedaudience,
              })
            }
          />
        </li>
        <li className="sd-category">
          <Technology
            onTechnologySelected={(technology) =>
              handleVariationsSelected({
                ...selectedVariations,
                selectedTechnology: technology,
              })
            }
          />
        </li>
        <li className="sd-category">
          <TechnologyFeatures
            onTechnologyFeaturesSelected={(technologyfeatures) =>
              handleVariationsSelected({
                ...selectedVariations,
                selectedTechnologyFeatures: technologyfeatures,
              })
            }
          />
        </li>
        <li className="sd-category">
          <Touchscreen
            onTouchscreenSelected={(touchscreen) =>
              handleVariationsSelected({
                ...selectedVariations,
                selectedTouchscreen: touchscreen,
              })
            }
          />
        </li>
        <li className="sd-category">
          <TrackingFeatures
            onTrackingFeaturesSelected={(trackingfeatures) =>
              handleVariationsSelected({
                ...selectedVariations,
                selectedTrackingFeatures: trackingfeatures,
              })
            }
          />
        </li>
        <li className="sd-category">
          <Transmission
            onTransmissionSelected={(transmission) =>
              handleVariationsSelected({
                ...selectedVariations,
                selectedTransmission: transmission,
              })
            }
          />
        </li>
        <li className="sd-category">
          <Tuning
            onTuningSelected={(tuning) =>
              handleVariationsSelected({
                ...selectedVariations,
                selectedTuning: tuning,
              })
            }
          />
        </li>
        <li className="sd-category">
          <Usage
            onUsageSelected={(usage) =>
              handleVariationsSelected({
                ...selectedVariations,
                selectedUsage: usage,
              })
            }
          />
        </li>
        <li className="sd-category">
          <UsageEnvironment
            onUsageEnvironmentSelected={(usageenvironment) =>
              handleVariationsSelected({
                ...selectedVariations,
                selectedUsageEnvironment: usageenvironment,
              })
            }
          />
        </li>
        <li className="sd-category">
          <UsageSurface
            onUsageSurfaceSelected={(usagesurface) =>
              handleVariationsSelected({
                ...selectedVariations,
                selectedUsageSurface: usagesurface,
              })
            }
          />
        </li>
        <li className="sd-category">
          <Warranty
            onWarrantySelected={(warranty) =>
              handleVariationsSelected({
                ...selectedVariations,
                selectedWarranty: warranty,
              })
            }
          />
        </li>
        <li className="sd-category">
          <WaterResistance
            onWaterResistanceSelected={(waterresistance) =>
              handleVariationsSelected({
                ...selectedVariations,
                selectedWaterResistance: waterresistance,
              })
            }
          />
        </li>
        <li className="sd-category">
          <Weight
            onWeightSelected={(weight) =>
              handleVariationsSelected({
                ...selectedVariations,
                selectedWeight: weight,
              })
            }
          />
        </li>
        <li className="sd-category">
          <WirelessConnectivity
            onWirelessConnectivitySelected={(wirelessconnectivity) =>
              handleVariationsSelected({
                ...selectedVariations,
                selectedWirelessConnectivity: wirelessconnectivity,
              })
            }
          />
        </li>
        <li className="sd-category">
          <Wood
            onWoodSelected={(wood) =>
              handleVariationsSelected({
                ...selectedVariations,
                selectedWood: wood,
              })
            }
          />
        </li>
        <li className="sd-sku">
          <span className="title">SKU :</span>
          {sku || ""}
        </li>
        <li className="sd-sku">
          <span className="title">Region/State:</span>
          {sellerregion || ""}
        </li>
      </ul>
    </>
  );
};

export default ProductVariations;
