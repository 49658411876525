import React from "react";
import Skeleton from "@mui/material/Skeleton";
import Grid from "@mui/material/Grid";

const ProductSkeletonLoader = ({height,length,lg="3",xl}) => {
  return (
    <Grid container spacing={3}>
      {[...Array(length)].map((_, index) => (
        <Grid item xs={12} sm={6} md={4} lg={lg} xl={xl} key={index}>
          <Skeleton variant="rectangular" width="100%" height={height} />
          <Skeleton variant="text" width="80%" height={20} />
          <Skeleton variant="text" width="60%" height={20} />
          {/* <Skeleton variant="text" width="40%" height={20} /> */}
        </Grid>
      ))}
    </Grid>
  );
};

export default ProductSkeletonLoader;
