import React from 'react';
import { ToastContainer } from 'react-toastify';
import Header from '../components/Header';

const PaymentMethods = () => {
	return (
		<>
			<Header />
			<section className='payment-methods-section py-5'>
				<div className='container-fluid'>
					<div className='row justify-content-center'>
						<div className='col-lg-8'>
							<div className='card shadow p-4'>
								<div className='card-header text-white text-center'>
									<h5 className='mb-0'>Payment Process on Africa Market</h5>
								</div>
								<div className='card-body'>
									<p className='lead'>
										Purchasing products on Africa Market is convenient and
										secure. Here's how the payment process works:
									</p>
									<ol>
										<li>
											<strong>Step 1: Checkout</strong>
											<br />
											After adding items to your cart and reviewing your order,
											proceed to the checkout page. Here, you'll see a summary
											of your order, including the total amount due.
										</li>
										<li>
											<strong>Step 2: Payment Methods</strong>
											<br />
											Select your preferred payment method from the available
											options. We accept various payment methods, including:
											<ul>
												<li>
													Credit or Debit Card (Visa, Mastercard, American
													Express)
												</li>
												<li>PayPal</li>
												<li>Bank Transfer</li>
												<li>Mobile Wallet (Apple Pay, Google Pay, etc.)</li>
											</ul>
										</li>
										<li>
											<strong>Step 3: Enter Payment Details</strong>
											<br />
											Depending on the payment method chosen, you may need to
											enter your payment details, such as credit card
											information or PayPal account details. Rest assured that
											we use encryption and other security measures to protect
											your payment information.
										</li>
										<li>
											<strong>Step 4: Authorization</strong>
											<br />
											Once you've entered your payment details, click on the
											"Place Order" or "Complete Purchase" button to authorize
											the payment. Your payment will be processed securely
											through our payment gateway.
										</li>
										<li>
											<strong>Step 5: Confirmation</strong>
											<br />
											After successfully completing the payment, you'll receive
											a confirmation message on the website indicating that your
											order has been placed. Additionally, you'll receive an
											email confirmation with details of your order and payment.
										</li>
										<li>
											<strong>Step 6: Order Processing</strong>
											<br />
											Our team will process your order promptly. If any
											additional verification is required for the payment, we'll
											reach out to you via email or phone.
										</li>
										<li>
											<strong>Step 7: Shipment</strong>
											<br />
											Once your order is processed and ready for shipment,
											you'll receive another email notification with tracking
											information. You can track the status of your order and
											estimated delivery date through your account dashboard.
										</li>
										<li>
											<strong>Step 8: Receipt</strong>
											<br />
											Upon delivery of your order, you'll receive a receipt
											either in the package or via email, confirming the
											successful delivery of your items.
										</li>
										<li>
											<strong>Step 9: Enjoy Your Purchase!</strong>
											<br />
											Now that your order has been successfully delivered, you
											can enjoy your products. If you have any questions or
											concerns about your order or payment, our customer support
											team is here to assist you.
										</li>
									</ol>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<ToastContainer />
		</>
	);
};

export default PaymentMethods;
