import React from "react";
import { API_BASE_URL } from "../config/connect";
import { NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import { add } from "../store/cartSlice";
import ViemoreProdSkeleton from "./ViemoreProdSkeleton";
import Nodata from "./Nodata";

function Products({ slicedProducts, loading, view }) {
  const dispatch = useDispatch();

  const addToCart = (product) => {
    // dispatch an add action
    dispatch(add(product));
  };

  if (loading) {
    return <ViemoreProdSkeleton />;
  } else if (slicedProducts?.length === 0) {
    return <Nodata />;
  } else {
    return (
      <>
        {slicedProducts?.map((post, index) => (
          <div
            key={index}
            className={
              view ? "col-xl-4 col-lg-6 col-md-6 col-sm-6" : "col-12 mb-3"
            }
          >
            <div className={`${view || "row custom"} shop-product-item mb-30`}>
              <div className={`${view || "col-3 col-xs-5"} shop-thumb`} style={{ position: "relative"}}>
                <NavLink to={`/shopdetail/${post.code}`}>
                  <img
                    src={post.previewpicture || post.picture}
                    alt="img"
                    width="250px"
                    height="auto"
                  />

{post.freedelivery === '1' && (
      <div
        style={{
          position: "absolute",
          top: "1px",   // Adjust the position as needed
          right: "3px", // Change this to `left: "10px"` for the left corner
          backgroundColor: "#F6921E", // Orange background
          color: "white", // White text color
          padding: "1px 10px", // Padding for better appearance
          fontSize: "12px", // Adjust text size as needed
          borderRadius: "5px", // Rounded corners
          height:"20px",
        }}
      >
        Free Delivery
      </div>
    )}
                </NavLink>
                {/* <span>New</span> */}
              </div>
              <div className={`${view || 'col-9 col-xs-7' } shop-content`}>
                <h2 className="title">
                  <NavLink to={`/shopdetail/${post.code}`}>{post.name}</NavLink>
                </h2>
                <ul className="tag">
                  <li>
                    Packaging - {/* <NavLink to="vendor-profile.html"> */}
                    {post.packagetype}
                    {/* </NavLink> */}
                  </li>
                </ul>
                <div className="rating d-flex">
                  <div>
                    {Array.from({ length: 5 }, (_, index) => (
                      <i
                        key={index}
                        className="fa-solid fa-star"
                        style={{
                          color:
                            index < Math.floor(post.ratingaverage)
                              ? "#ffc107"
                              : "#e4e5e9",
                        }}
                      ></i>
                    ))}
                  </div>
                  <div>
                    <h6
                      style={{
                        fontWeight: "normal",
                        fontSize: "12px",
                        marginLeft: "3px",
                        marginTop: "9px",
                      }}
                    >
                      ({post.ratingcount})
                    </h6>
                  </div>
                </div>
                {/* <span>Discounted : 20%</span>
                <div className="progress">
                  <div
                    className="progress-bar w-50"
                    role="progressbar"
                    aria-valuenow="50"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div> */}
                <div className="content-bottom">
                  <h4 className="price">{` GH₵${Number(post.price).toFixed(2).toLocaleString()}`}</h4>
                  <p
                    // onClick={() => addToCart(post)}
                    style={{ cursor: "pointer", padding: "5px 10px" }}
                  >
                    {/* <span>Add To Cart</span> */}
                  </p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </>
    );
  }
}

export default Products;
