import React from "react";
import { ThreeDots } from "react-loader-spinner";
import "../assets/css/loader.css";
import Logo from '../assets/img/logo/noBgAfricaMarketLogoStrip.png'

const Loader = () => {
  return (
    <div className="parent-container">
      <div className="loader-container">
        <div className="afmlogo animate__pulse">
          <img src={Logo} alt="africamarketslogo" />
        </div>
        <div>
          <ThreeDots
            visible={true}
            height="80"
            width="80"
            color="#ff4800"
            radius="9"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>
      </div>
    </div>
  );
};

export default Loader;
